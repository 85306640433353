import { FC, useEffect, useMemo, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { IContributorAttributes } from '../interfaces/contributor'
import { ContributorId, getToken, Profile, TypeLookup } from '../stores/appStore'
import Layout from './Layout'
import TextInput from '../components/common/Textinput'
import { Button } from '@mui/material'
import { updateUserdemographics, updateUsername } from '../api/contributor'
import TypeList from '../components/common/TypeList'
import { SystemTypeEnum } from '../enums/SystemTypeEnum'
import { ITypeAttributes } from '../interfaces/type'
import { useTypeHierarchy } from '../components/hooks/useTypeHierarchy'
import { IDemographicAttributes } from '../interfaces/demographic'
import Loading from '../components/common/Loading'
import { IItemAttributes } from '../interfaces/item'
import ItemList from './profile/ItemList/ItemList'

import styles from './Profile.module.scss'

const ProfilePage: FC = () => {
  const [profile, setProfile] = useState<IContributorAttributes | null>(null)
  const [username, setUsername] = useState<string | null>(null)
  const [usernameDirty, setUsernameDirty] = useState(false)
  const [usernameError, setUsernameError] = useState<string>('')
  const [demographicsDirty, setDemographicsDirty] = useState(false)
  const [saving, setSaving] = useState(false)
  const contributorId = useRecoilValue(ContributorId)
  const [items, setItems] = useState<IItemAttributes[] | null>(null)

  const [demographics, setDemographics] = useState<IDemographicAttributes[] | null>(null)

  const demographicsType = useTypeHierarchy(SystemTypeEnum.Demographics)

  const demographicsChildren = demographicsType && (demographicsType.children as ITypeAttributes[])

  const loadableProfile = useRecoilValueLoadable(Profile)
  const refreshProfile = useRecoilRefresher_UNSTABLE(Profile)
  const refreshTypes = useRecoilRefresher_UNSTABLE(TypeLookup)

  useEffect(() => {
    if (loadableProfile.state !== 'hasValue' || !loadableProfile.contents) return
    setProfile(loadableProfile.contents)
    setUsername(loadableProfile.contents.contributorName)
    setDemographics(loadableProfile.contents.demographics?.map((d) => ({ ...d })) || [])
    setItems(loadableProfile.contents.items?.map((d) => ({ ...d })) || [])
    console.log(loadableProfile.contents)
  }, [loadableProfile.contents, loadableProfile.state])

  const onUsernameChanged = (value: string) => {
    setUsernameDirty(true)
    setUsername(value)
  }
  const invalid = useMemo(() => {
    return !!usernameError
  }, [username]) // eslint-disable-line

  if (!profile) return null

  const saveUsernameClicked = async () => {
    setSaving(true)
    let refresh = false
    const accessToken = await getToken()
    if (username && username !== profile.contributorName) {
      const updated = await updateUsername(accessToken, profile.email, username)
      refresh = !!updated
    }
    if (refresh) refreshProfile()
    setUsernameDirty(false)
    setSaving(false)
  }

  const saveDemographicsClicked = async () => {
    setSaving(true)
    let refresh = false
    const accessToken = await getToken()
    if (demographics && demographicsDirty) {
      const updatedDemo = await updateUserdemographics(accessToken, contributorId, demographics)
      if (updatedDemo) {
        refreshTypes()
      }
      refresh = !!updatedDemo
    }
    if (refresh) refreshProfile()
    setDemographicsDirty(false)
    setSaving(false)
  }
  const onTypeChanged = (val: ITypeAttributes, parent: ITypeAttributes) => {
    if (!demographics) return
    const existingDemo = parent.children?.find((c) => c.id === val.id)
    const newDemo = {
      contributorId,
      typeId: parent.id,
      value: existingDemo
        ? existingDemo.id
        : val.title.startsWith('Add')
        ? val.title.slice(5, val.title.length - 1)
        : val.title,
    }
    if (!existingDemo)
      parent.children?.push({
        id: newDemo.value,
        parentId: parent.id,
        parent: parent,
        title: newDemo.value,
        icon: '',
        contributorId,
        timestamp: new Date().valueOf(),
      })
    setDemographicsDirty(true)
    setDemographics((current) => (!current ? [newDemo] : [...current.filter((c) => c.typeId !== parent.id), newDemo]))
  }

  return (
    <Layout>
      {saving ? (
        <Loading message='Saving profile'></Loading>
      ) : (
        <div className={styles.main}>
          <div className={styles.column}>
            <h3>User Profile</h3>
            <div className={styles.row}>
              <TextInput
                label='User Name'
                value={username || ''}
                onValueChanged={onUsernameChanged}
                validation={{ required: true, range: { min: 3, max: 50 } }}
                onErrorChanged={(err) => setUsernameError(err)}
              ></TextInput>
              <Button variant='outlined' disabled={!usernameDirty || invalid} onClick={saveUsernameClicked}>
                update username
              </Button>
              <Button variant='outlined' disabled={!usernameDirty} onClick={() => refreshProfile()}>
                cancel
              </Button>
            </div>
            <div className={styles.header}>
              <h3>Demographics</h3>
              <p>(optional) select from list, or add a new value.</p>
            </div>
            {demographicsChildren?.map((t) => {
              const value = t.children?.find((c) => demographics?.some((d) => c.id === d?.value)) || null
              return (
                <div className={styles.row} key={t.id}>
                  <TypeList
                    onValueChanged={(val) => onTypeChanged(val, t)}
                    label={t.title}
                    systemType={t.id}
                    value={value}
                  ></TypeList>
                </div>
              )
            })}

            <div className={styles.row}>
              <Button variant='outlined' disabled={!demographicsDirty} onClick={saveDemographicsClicked}>
                save demographics
              </Button>
              <Button variant='outlined' disabled={!demographicsDirty} onClick={() => refreshProfile()}>
                cancel
              </Button>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.header}>
              <h3>Items</h3>
            </div>
            <ItemList items={items}></ItemList>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default ProfilePage
