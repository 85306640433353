import { Button, FormControl, FormHelperText, Icon, InputLabel, OutlinedInput, Tooltip } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import {
  AttributePlacement,
  IAttributeAttributes,
  //IAttributeChild,
  IAttributeValidation,
} from '../../interfaces/attribute'
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd'
import { StrictModeDroppable as Droppable } from './StrictModeDroppable'
import { v4 as guid } from 'uuid'
import { useTypeHierarchy } from '../hooks/useTypeHierarchy'
import { SystemTypeEnum } from '../../enums/SystemTypeEnum'
import { ITypeAttributes } from '../../interfaces/type'

import Style from '../../styles/controls/Controls.module.sass'

interface IAttributeChildrenProps {
  attribute?: IAttributeAttributes
  label?: string
  tooltip?: string
  onChildrenChanged?: (children: IAttributeAttributes[]) => void
}
const AttributeChildren: FC<IAttributeChildrenProps> = (props) => {
  const { attribute, label, tooltip, onChildrenChanged } = props
  const [value, setValue] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [listType, setListType] = useState<ITypeAttributes | null>(null)
  const inputRef = useRef<HTMLInputElement>()
  const attributeType = useTypeHierarchy(SystemTypeEnum.Attributes)

  useEffect(() => {
    if (attributeType?.children) {
      const dropdown = attributeType?.children?.find((t) => t.title == 'dropdown')
      console.log(dropdown)
      if (dropdown) setListType(dropdown)
    }
  }, [attributeType])

  const handleChange = (newValue: string | null) => {
    console.log(newValue)
    setValue(newValue || '')
  }
  const validation: IAttributeValidation = {
    type: 'string',
  }
  useEffect(() => {
    if (attribute?.children?.find((c) => c.title === value)) {
      setError('Value exists')
      return
    }
    setError('')
  }, [attribute?.children, value])

  const addChild = () => {
    if (!attribute) return
    if (!attribute.children) attribute.children = []
    attribute.children.push({
      id: guid(),
      sortOrder: attribute.children.length,
      title: value,
      contributorId: '',
      placement: AttributePlacement.none,
      templateId: attribute.templateId,
      typeId: listType?.id || '',
      timestamp: new Date().valueOf(),
    })
    setValue('')
    inputRef.current?.focus()
    onChildrenChanged && onChildrenChanged(attribute.children)
  }

  // a little function to help us with reordering the result
  const reorder = (children: IAttributeAttributes[], startIndex: number, endIndex: number) => {
    const result = Array.from(children)
    const [removed] = children.splice(startIndex, 1)
    children.splice(endIndex, 0, removed)
    children.forEach((child, index) => (child.sortOrder = index))

    return result
  }

  const getListClass = (isDraggingOver: boolean): string =>
    isDraggingOver ? `${Style.dragContainer} ${Style.dragging}` : Style.dragContainer

  const onDragEnd = (result: DropResult) => {
    console.log(result)
    if (!attribute?.children || !result.destination) {
      return
    }
    reorder(attribute.children, result.source.index, result.destination.index)
    onChildrenChanged && onChildrenChanged(attribute.children)
  }

  const list = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='childrenlist'>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={getListClass(snapshot.isDraggingOver)}>
            {attribute?.children &&
              attribute?.children.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={snapshot.isDragging ? `${Style.dragItem} ${Style.dragging}` : Style.dragItem}
                    >
                      {item.title}-{item.sortOrder}
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !!value.length && !error) {
      addChild()
    }
  }
  return (
    <div className={Style.wrapper} style={{ flex: '10 1', display: 'flex' }}>
      {list}
      <FormControl fullWidth>
        <InputLabel required={validation.required}>{label}</InputLabel>
        <div className={Style.inputIcon}>
          <OutlinedInput
            fullWidth
            label={`${label}${validation.required ? ' *' : ''}`}
            autoComplete='off'
            error={!!error}
            value={value || ''}
            onChange={(e) => handleChange(e.target.value)}
            onKeyDown={onKeyDown}
            inputRef={inputRef}
            endAdornment={
              <Button variant='outlined' style={{ width: '20%' }} disabled={!value || !!error} onClick={addChild}>
                add
              </Button>
            }
          />
          {tooltip && (
            <Tooltip title={tooltip}>
              <Icon>help_centre</Icon>
            </Tooltip>
          )}
        </div>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </div>
  )
}

export default AttributeChildren
