import { FC } from 'react'
import ListInput from './common/ListInput'
import { useContributorLookup } from './hooks/useContributorLookup'

interface ICollaboratorsProps {
  values: string[]
  tooltip?: string
  label?: string
  onValueChanged: (val: string[]) => void
}

const Collaborators: FC<ICollaboratorsProps> = (props) => {
  const { values, tooltip, label, onValueChanged } = props

  const contributors = useContributorLookup()
  return (
    <ListInput
      options={contributors || []}
      label={label}
      values={values}
      tooltip={tooltip}
      onValueChanged={(val) => onValueChanged && onValueChanged(val)}
    ></ListInput>
  )
}
export default Collaborators
