import { IAttributeAttributes } from '../interfaces/attribute'
import { ITemplateAttributes } from '../interfaces/template'

const routerPath = `${process.env.REACT_APP_OHM_API_BASEURL || ''}/api/template`

export const getTemplates = async (token: string): Promise<ITemplateAttributes[] | null> => {
  try {
    const response = await fetch(`${routerPath}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })
    return response.ok ? response.json() : null
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getTemplatesLookup = async (token: string): Promise<ITemplateAttributes[] | null> => {
  try {
    const response = await fetch(`${routerPath}/lookup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })
    return response.ok ? response.json() : null
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getTemplateById = async (token: string, id: string): Promise<ITemplateAttributes | null> => {
  try {
    const response = await fetch(`${routerPath}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })
    return response.ok ? response.json() : null
  } catch (error) {
    console.log(error)
    return null
  }
}

export const saveTemplate = async (
  token: string,
  template: ITemplateAttributes,
  attributes: IAttributeAttributes[],
): Promise<ITemplateAttributes | null> => {
  try {
    const body = { ...template, attributes }
    const response = await fetch(`${routerPath}/add`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    })
    return response.ok ? response.json() : null
  } catch (error) {
    console.log(error)
    return null
  }
}
