import { useEffect, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { SystemTypeEnum } from '../../enums/SystemTypeEnum'
import { createHierarchy } from '../../interfaces/base'
import { ITypeAttributes } from '../../interfaces/type'
import { TypeLookup } from '../../stores/appStore'

export const useTypeHierarchy = (parentId: SystemTypeEnum | string): ITypeAttributes | null => {
  const [type, setType] = useState<ITypeAttributes | null>(null)

  const loadableTypes = useRecoilValueLoadable(TypeLookup)

  useEffect(() => {
    if (loadableTypes.state !== 'hasValue' || !loadableTypes.contents) return

    const allTypes = loadableTypes.contents.map((t) => ({ ...t }))
    const hierarchy = createHierarchy(allTypes, { parentId })
    if (hierarchy.length && hierarchy[0].id === parentId) setType(hierarchy[0])
  }, [loadableTypes.contents, loadableTypes.state, parentId])

  return type
}
