import { FC, Fragment, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import './App.css'
import { useAuth0 } from '@auth0/auth0-react'
import { User } from '@auth0/auth0-spa-js'
import LoginPage from './pages/Login'
import { checkUser } from './api/contributor'
import { ThemeProvider } from '@emotion/react'
import { createTheme, CssBaseline } from '@mui/material'
import Loading from './components/common/LoadingMessage'
import { useRecoilState } from 'recoil'
import { ContributorId, ContributorRole, getToken } from './stores/appStore'
import SubmissionPage from './pages/Submission'
import Layout from './components/layouts/LayoutGrey'
import ErrorDisplay from './components/common/ErrorDisplay'
import ProfilePage from './pages/Profile'
import OrganisationPage from './pages/Organisation'
import Rating from './pages/Rating'
import TemplatePage from './pages/Template'
import { ContributorRoleEnum } from './enums/ContributorRoleEnum'
import { getVersion } from './api/application'

const App: FC = () => {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)

  const [contributorId, setContributorId] = useRecoilState(ContributorId)
  const [contributorRole, setContributorRole] = useRecoilState(ContributorRole)

  const [error, setError] = useState<Error | null>(null)

  // wait for Auth0 then start initial request flow
  useEffect(() => {
    async function refreshAuth(user: User) {
      const accessToken = await getToken()
      const version = await getVersion(accessToken)
      console.log(`wisdohm version ${version}`)
      const contributor = await checkUser(accessToken, user)
      setContributorId(contributor?.id || '')
      if (contributor?.roles?.length) setContributorRole(contributor.roles[0].role)
      setLoading(false)
    }
    if (!user || contributorId) return

    setLoading(true)
    refreshAuth(user).catch(setError)
  }, [user, getAccessTokenSilently, setContributorId, contributorId, setContributorRole])

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#EC9E00', //BE6B02
      },
      secondary: {
        main: '#FFF',
      },
    },
  })

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Routes>
        {error ? (
          <Route
            key='error'
            path='*'
            element={
              <Layout>
                <ErrorDisplay message='Login failure' error={error} />
              </Layout>
            }
          />
        ) : (isLoading && !isAuthenticated) || loading ? (
          <Route
            key='loading'
            path='*'
            element={
              <Layout>
                <Loading message='Loading...' />
              </Layout>
            }
          />
        ) : isAuthenticated && contributorId ? (
          <Fragment>
            <Route key='root' path='/' element={<Navigate to={'/ratings'} />} />
            <Route key='ratings' path='/ratings' element={<Rating />} />
            {contributorRole === ContributorRoleEnum.Manager && (
              <>
                <Route key='organisation' path='/organisation' element={<OrganisationPage />} />
                <Route key='templates' path='/templates' element={<TemplatePage />} />
                <Route key='submissions' path='/submissions' element={<SubmissionPage />} />
                <Route key='profile' path='/profile' element={<ProfilePage />} />
              </>
            )}
            <Route key='not found' path='*' element={<Navigate to={'/ratings'} />} />
          </Fragment>
        ) : null}
        <Route key='login' path='*' element={<LoginPage />} />
      </Routes>
    </ThemeProvider>
  )
}

export default App
