import { FC, useEffect, useState } from 'react'

//import { ITypeAttributes } from '../../interfaces/type'
import { createHierarchy } from '../../interfaces/base'
//import { getTypesLookup } from '../../api/type'

import Style from '../../styles/controls/Treeview.module.sass'
import { getItemHierarchy } from '../../api/item'
//import { getContributors } from '../../api/contributor'
import { IItemAttributes } from '../../interfaces/item'
import { Icon } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'

interface ITreeViewProps {
  parentId?: string
  onSelected?: (item: IItemAttributes) => void
}

interface IItemOpen {
  [x: string]: boolean
}

const TreeView: FC<ITreeViewProps> = (props) => {
  const { parentId, onSelected } = props
  // const [item, setItem] = useState<IItemAttributes | undefined>()
  // const [template, setTemplate] = useState<ITemplateAttributes>()

  //const [types, setTypes] = useState<ITypeAttributes[]>()
  // const [templates, setTemplates] = useState<ITemplateAttributes[]>()

  //const [contributors, setContributors] = useState<IBaseTitle[]>()
  // const [title, setTitle] = useState<string>()
  // const [typeId, setTypeId] = useState<string>()
  // const [templateId, setTemplateId] = useState<string>()
  // const [contributorId, setContributorId] = useState<string>()
  const [loading, setLoading] = useState(false)

  const [items, setItems] = useState<IItemAttributes[]>()
  const [itemOpen, setItemOpen] = useState<IItemOpen>({})
  const [selected, setSelected] = useState<string>()
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (loading) return
    setLoading(true)

    const effectAsync = async () => {
      if (loading) return
      const accessToken = await getAccessTokenSilently()
      const items = await getItemHierarchy(accessToken, parentId, 6)
      if (items) {
        setItems(createHierarchy(items, { sort: { field: 'title' } }))
      }

      setLoading(false)
    }

    effectAsync()
  }, []) //eslint-disable-line

  const toggleItemOpen = (id: string) => {
    setItemOpen((current) => ({ ...current, [id]: !current[id] }))
  }
  const selectionChanged = (item: IItemAttributes) => {
    setItemOpen((current) => ({ ...current, [item.id]: true }))
    setSelected(item.id)
    onSelected && onSelected(item)
  }

  const renderItem = (item: IItemAttributes) => {
    const hasChildren = (item.children && item.children.length > 0) || false
    const open = (hasChildren && itemOpen[item.id]) || false
    const chevron = <Icon>{open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
    return (
      <div key={item.id} className={Style.row}>
        <div className={`${Style.item} ${selected == item.id && Style.selected}`}>
          <Icon>{item.type?.icon || 'star'}</Icon>
          <div className={Style.title} onClick={() => selectionChanged(item)}>
            {item.title}
          </div>
          <div className={Style.chevron} onClick={() => toggleItemOpen(item.id)}>
            {hasChildren && chevron}
          </div>
        </div>
        {hasChildren && open && (
          <div className={Style.itemChildren}>{item.children?.map((i) => renderItem(i as IItemAttributes))}</div>
        )}
      </div>
    )
  }
  if (loading) return <div>loading...</div>
  return <div className={Style.wrapper}>{items?.map((item) => renderItem(item))}</div>
}

export default TreeView
