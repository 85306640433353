import { ITypeAttributes } from './type'

export const WISDOHM_ROOT = '00000000-0000-0000-0000-000000000000'

export interface IBaseId {
  id: string
}

export interface IBaseAudit {
  contributorId: string
  timestamp: number | null
}

export interface IBaseType extends IBaseId {
  typeId: string
  type?: ITypeAttributes
}
export interface IBaseTitle extends IBaseId {
  title: string
}

export interface IBaseExternalId extends IBaseId {
  externalId?: string
}

export interface IBaseSelfRefAttributes<T> extends IBaseId {
  parentId?: string
  parent?: T
  children?: T[]
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isIBaseId(obj: any): obj is IBaseId {
  return 'id' in obj
}

export function isIBaseAudit(obj: any): obj is IBaseAudit {
  return 'contributorId' in obj && 'timestamp' in obj
}

export function isIBaseType(obj: any): obj is IBaseType {
  return 'typeId' in obj
}

export function isIBaseTitle(obj: any): obj is IBaseTitle {
  return 'title' in obj
}

export function isIBaseSelfRefAttributes<T>(obj: any): obj is IBaseSelfRefAttributes<T> {
  return 'parentId' in obj
}

export function isIBaseArray(obj: any): obj is IBaseId[] {
  // eslint-disable-next-line
  type objtype = typeof obj[number]
  return 'length' in obj && isIBaseId(<objtype>{})
}

export function isIBaseTitleArray(obj: any): obj is IBaseId[] {
  // eslint-disable-next-line
  type objtype = typeof obj[number]
  return 'length' in obj && isIBaseTitle(<objtype>{})
}

/* eslint-enable */

interface IHierarchyOptions<T extends IBaseSelfRefAttributes<T>> {
  sort?: {
    field?: keyof T
    func?: (a: T, b: T) => number
  }
  parentId?: string
}

export const createHierarchy = <T extends IBaseSelfRefAttributes<T>>(flat: T[], options?: IHierarchyOptions<T>) => {
  if (flat.length < 2) return flat as T[]

  const sort = (a: T, b: T) => {
    if (options?.sort?.func) return options?.sort?.func(a, b)
    if (options?.sort?.field)
      return a[options.sort.field] < b[options.sort.field] ? -1 : b[options.sort.field] < a[options.sort.field] ? 1 : 0
    if (isIBaseTitle(a) && isIBaseTitle(b)) return a['title'] < b['title'] ? -1 : b['title'] < a['title'] ? 1 : 0
    if (isIBaseAudit(a) && isIBaseAudit(b))
      return (a['timestamp'] || 0) < (b['timestamp'] || 0) ? -1 : (b['timestamp'] || 0) < (a['timestamp'] || 0) ? 1 : 0
    return 0
  }

  for (let index = 0; index < flat.length; index++) {
    const element = flat[index]

    element.children = flat.filter((f) => f.parentId === element.id && f.parentId != f.id).sort(sort)
    element.parent = flat.find((f) => f.id === element.parentId && element.parentId != element.id)
  }

  const result = flat.filter((f) => (options?.parentId ? f.id === options?.parentId : !f.parent)).sort(sort)
  return result as T[]
}

export const findInHierarchy = <T extends IBaseSelfRefAttributes<T>>(root: T, id: string): T | null => {
  root.children?.forEach((c) => {
    if (c.id === id) return c
  })
  root.children?.forEach((c) => {
    const found = findInHierarchy(c, id)
    if (found) return found
  })
  return null
}
