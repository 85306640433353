import { FC, useEffect, useMemo, useState } from 'react'

import { ITemplateAttributes } from '../../interfaces/template'
import TextInput from '../../components/common/Textinput'
import { IAttributeValidation } from '../../interfaces/attribute'

import { Button } from '@mui/material'
import { validate } from '../../components/hooks/useValidation'
import { saveItem } from '../../api/item'
import { IItemAttributes } from '../../interfaces/item'
import AttributeControl from '../../components/attributes/AttributeControl'

import { ISubmissionAttributes } from '../../interfaces/submission'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'
import { ContributorId, ContributorRole, Profile, getToken } from '../../stores/appStore'
import { sortAttributes } from '../../utils/utils'
import Item from '../rating/Item/Item'
import { IValueAttributes } from '../../interfaces/value'
import Dropdown from '../../components/common/Dropdown'

import Style from './Item.module.sass'
import { useContributorLookup } from '../../components/hooks/useContributorLookup'
import { ContributorRoleEnum } from '../../enums/ContributorRoleEnum'
import Loading from '../../components/common/Loading'

interface IItemProps {
  submission: ISubmissionAttributes
  onSaved?: () => void
}

export interface IAttributeValues {
  [id: string]: string
}

const AddItem: FC<IItemProps> = (props) => {
  const { submission, onSaved } = props
  const [template, setTemplate] = useState<ITemplateAttributes>()
  const [saving, setSaving] = useState(false)

  const contributors = useContributorLookup()

  const [values, setValues] = useState<IAttributeValues>({})

  const [errors, setErrors] = useState<IAttributeValues>({})
  const contributorId = useRecoilValue(ContributorId)
  const contributorRole = useRecoilValue(ContributorRole)
  const [childItems, setChildItems] = useState<IAttributeValues[]>([])

  const refreshProfile = useRecoilRefresher_UNSTABLE(Profile)

  const createChild = (
    parentId: string,
    typeId: string,
    templateId: string,
    values: IAttributeValues,
  ): IAttributeValues => {
    const child = {
      ['parentId']: parentId || '',
      ['typeId']: typeId || '',
      ['contributorId']: contributorId || '',
      ['templateId']: templateId,
      ...values,
    }
    return child
  }

  useEffect(() => {
    if (!submission || !contributorId) return
    const newValues: IAttributeValues = {
      ['parentId']: submission.parentId || '',
      ['typeId']: submission.template.typeId || '',
      ['contributorId']: contributorId || '',
      ['templateId']: submission.templateId,
      ['actual']: `${submission.actual || false}`,
    }
    const temp = {
      ...submission.template,
      //attributes: createHierarchy(submission.template.attributes || [], { sort: { field: 'sortOrder' } }),
      attributes:
        submission.template.attributes?.map((a) => {
          return { ...a }
        }) || [],
    }

    setTemplate(temp)
    setValues(newValues)
  }, [submission, contributorId])

  const onValueChanged = (field: keyof IItemAttributes, val: string) => {
    setValues((current) => {
      return { ...current, [field]: val }
    })
  }

  const invalid = useMemo(() => {
    if (!template || !template.attributes) return true
    let errs = errors
    template.attributes.forEach((attr) => {
      const value = values[attr.id]
      const valid: IAttributeValidation = !attr.validation ? {} : JSON.parse(attr.validation)
      errs = { ...errs, [attr.id]: validate(valid, value) }
    })
    setErrors(errs)
    return template.attributes.some((a) => errs[a.id]?.length)
  }, [template, values]) // eslint-disable-line

  const onChildrenChanged = (field: keyof IItemAttributes, typeId: string, val: string[]) => {
    console.log(
      'children changed',
      val,
      val.map((v) => createChild(values.id, typeId, '', { id: v })),
    )
    setChildItems((current) => {
      return [
        ...current.filter((c) => c.typeId !== typeId),
        ...val.map((v) => createChild(values.id, typeId, '', { id: v })),
      ]
    })
  }
  const saveClicked = async () => {
    setSaving(true)
    const accessToken = await getToken()
    saveItem(accessToken, values, childItems)
    setSaving(false)
    onSaved && onSaved()
    refreshProfile()
  }

  const itemFromAttributes = useMemo(() => {
    const itemId = values['id'] || 'sampleid'
    const v: IValueAttributes[] = []
    Object.entries<string>(values).forEach((entry) => {
      const [attId, attVal] = entry
      if (!['title', 'typeId', 'parentId', 'contributorId', 'actual', 'templateId'].some((n) => n === attId)) {
        const attr = template?.attributes?.find((a) => a.id === attId)

        v.push({
          itemId,
          attributeId: attId,
          value: attVal,
          contributorId,
          attribute: attr,
          id: attId,
          typeId: attr?.typeId || '',
          timestamp: new Date().valueOf(),
        })
      }
    })

    return {
      id: itemId,
      title: values['title'],
      typeId: values['typeId'],
      contributorId: values['contributorId'],
      actual: !!values['actual'],
      values: v,
      timestamp: new Date().valueOf(),
    } as IItemAttributes
  }, [contributorId, template?.attributes, values])

  return saving ? (
    <Loading message='Saving Item'></Loading>
  ) : (
    <>
      <div className={Style.dualColumn}>
        <div className={Style.scroller}>
          <h3>Add Item</h3>
          <div className={Style.row}>
            <TextInput
              label='Title'
              validation={{ required: true, range: { min: 5, max: 150 } }}
              value={values['title']}
              onValueChanged={(val) => onValueChanged('title', val)}
            />
          </div>
          {!template?.attributes?.length ? (
            <div></div>
          ) : (
            template.attributes.sort(sortAttributes).map((attr) => (
              <div key={attr.id} className={Style.row}>
                <AttributeControl
                  value={values[attr.id] || ''}
                  onValueChanged={(val) =>
                    setValues((current) => {
                      return { ...current, [attr.id]: val }
                    })
                  }
                  childItems={childItems.map((c) => c.id)} //.filter((c) => c.type === attr.typeId)
                  onChildrenChanged={(values) => onChildrenChanged('children', attr.typeId, values)}
                  attribute={attr}
                ></AttributeControl>
              </div>
            ))
          )}
          <div className={Style.row}>
            <Dropdown
              label='Contributor'
              validation={{ required: true }}
              value={values['contributorId']}
              options={contributors || []}
              onValueChanged={(val) => onValueChanged('contributorId', val)}
              disabled={contributorRole !== ContributorRoleEnum.Manager}
            />
          </div>

          <div className={Style.row}>
            <Button variant='outlined' disabled={invalid} onClick={saveClicked}>
              save
            </Button>
          </div>
        </div>
        <div className={Style.scroller}>
          <h3>Preview Item</h3>
          <Item attributes={template?.attributes} item={itemFromAttributes}></Item>
        </div>
      </div>
    </>
  )
}

export default AddItem
