import {
  FormControl,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { IAttributeValidation } from '../../interfaces/attribute'
import { IBaseTitle, WISDOHM_ROOT } from '../../interfaces/base'
import { isITypeAttribute } from '../../interfaces/type'
import { useValidation } from '../hooks/useValidation'

import Style from '../../styles/controls/Controls.module.sass'

interface IDropdownProps {
  value?: string
  label?: string
  validation?: IAttributeValidation
  options: IBaseTitle[]
  tooltip?: string
  disabled?: boolean
  readonly?: boolean
  onValueChanged: (val: string) => void
  onErrorChanged?: (error: string) => void
  reload?: boolean
}

const Dropdown: FC<IDropdownProps> = (props) => {
  const { value, label, validation, tooltip, disabled, reload, readonly, onErrorChanged } = props
  const [focused, setFocused] = useState(true)
  const [loading, setLoading] = useState(true)
  const [error, errorMessage] = useValidation(validation, value, focused, onErrorChanged)

  // eslint-disable-next-line
  const handleChange = (event: SelectChangeEvent<any>, child: React.ReactNode) => {
    props.onValueChanged(event.target.value)
  }

  const dropdownOptions = useMemo(() => {
    if (!props.options.length) {
      setLoading(true)
      return [{ id: WISDOHM_ROOT, title: 'loading', icon: '' }]
    }
    setLoading(false)
    return props.options.map((o) => {
      return { ...o, icon: isITypeAttribute(o) ? o.icon : 'star' }
    })
  }, [props.options, reload]) // eslint-disable-line

  return (
    <div className={Style.wrapper}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <div className={Style.inputIcon}>
          <Select
            fullWidth
            required={validation?.required}
            error={error}
            value={!loading ? props.value || '' : WISDOHM_ROOT}
            label={props.label}
            autoComplete='off'
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={handleChange}
            disabled={disabled}
            endAdornment={
              tooltip && (
                <Tooltip title={tooltip}>
                  <Icon>help_centre</Icon>
                </Tooltip>
              )
            }
            readOnly={readonly}
          >
            {dropdownOptions.map((o) => {
              return (
                <MenuItem key={o.id} value={o.id}>
                  <Icon>{o.icon}</Icon>
                  <span>{o.title}</span>
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    </div>
  )
}

export default Dropdown
