const routerPath = `${process.env.REACT_APP_OHM_API_BASEURL || ''}/api/app`

export const getVersion = async (token: string) => {
  try {
    const response = await fetch(`${routerPath}/version`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })
    return response.ok ? response.text() : null
  } catch (error) {
    console.log(error)
  }
}
