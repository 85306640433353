import { formatISO } from 'date-fns'
import { FC, useMemo } from 'react'
import { IAttributeAttributes, IAttributeValidation } from '../../interfaces/attribute'
import { IBaseTitle } from '../../interfaces/base'
import Collaborators from '../Collaborators'
import Datepicker from '../common/Datepicker'
import Dropdown from '../common/Dropdown'
import TextInput from '../common/Textinput'

interface IAttributeControlProps {
  attribute: IAttributeAttributes
  value?: string
  type?: string
  childItems?: string[]
  readonly?: boolean
  onValueChanged?: (val: string) => void
  onChildrenChanged?: (val: string[]) => void
  reload?: boolean
}
const AttributeControl: FC<IAttributeControlProps> = (props) => {
  const { attribute, value, type, onValueChanged, reload, readonly, childItems, onChildrenChanged } = props
  const control = useMemo(() => {
    console.log(reload, attribute)
    const validation: IAttributeValidation = !attribute.validation ? {} : JSON.parse(attribute.validation)
    switch (type || attribute.type?.title) {
      case 'dropdown':
        return (
          <Dropdown
            tooltip={attribute.description}
            validation={{ ...validation, list: (attribute.children as unknown as IBaseTitle[]) || [] }}
            label={attribute.title}
            value={value || ''}
            options={(attribute.children as unknown as IBaseTitle[]) || []}
            onValueChanged={(val) => onValueChanged && onValueChanged(val)}
            reload={reload}
            readonly={readonly}
          />
        )
      case 'array':
        return (
          <Collaborators
            tooltip={attribute.description}
            //validation={validation}
            label={attribute.title}
            values={childItems || []}
            //options={(attribute.children as unknown as IBaseTitle[]) || []}
            onValueChanged={(val) => onChildrenChanged && onChildrenChanged(val)}
          />
        )
      case 'date':
        return (
          <Datepicker
            tooltip={attribute.description}
            validation={validation}
            label={attribute.title}
            value={value || ''}
            onValueChanged={(val) => onValueChanged && onValueChanged(val ? formatISO(val) : '')}
            readonly={readonly}
          />
        )

      default:
        return (
          <TextInput
            tooltip={attribute.description}
            validation={validation}
            label={attribute.title}
            value={value || ''}
            onValueChanged={(val) => onValueChanged && onValueChanged(val)}
            readonly={readonly}
          />
        )
    } // eslint-disable-next-line
  }, [
    attribute.children,
    attribute.description,
    attribute.title,
    attribute.type?.title,
    attribute.validation,
    onValueChanged,
    type,
    value,
    reload,
  ])

  return control
}

export default AttributeControl
