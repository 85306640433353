import { IAttributeAttributes, IAttributeValues } from '../interfaces/attribute'
import { IItemAttributes } from '../interfaces/item'

export const sortAttributes = (a: IAttributeAttributes, b: IAttributeAttributes) => {
  return a.sortOrder < b.sortOrder ? -1 : b.sortOrder < a.sortOrder ? 1 : 0
}

type IdTitle<T> = {
  [K in keyof T]: {
    readonly id: T[K]
    readonly title: string
  }
}[keyof T]

export function enumToIdTitleArray<T extends object>(enumeration: T): IdTitle<T>[] {
  return (Object.keys(enumeration) as Array<keyof T>)
    .filter((key) => isNaN(Number(key)))
    .filter((key) => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
    .map((key) => ({
      id: enumeration[key],
      title: String(key),
    }))
}

interface IStringString {
  [x: string]: string
}
const escapeHTMLChars: IStringString = {
  '"': '&quot;',
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
}
export function escapeHTML(unsafe: string) {
  return unsafe.replace(/["&<>]/g, function (matched) {
    return escapeHTMLChars[matched]
  })
}

export const linkify = (t: string) => {
  const isValidHttpUrl = (s: string) => {
    let u
    try {
      u = new URL(s)
    } catch (_) {
      return false
    }
    return u.protocol.startsWith('http')
  }
  let enc = escapeHTML(t)
  const m = enc.match(/(?<=\s|\50|^)[a-zA-Z0-9-:/]{2,}\.[a-zA-Z0-9-].+?(?=[.,;:?!-]?(?:\s|\51|$))/g)
  console.log({ t: t.slice(0, 100), enc: enc.slice(0, 100), m: m?.slice(0, 100) })
  if (!m) return enc
  const a = []
  m.forEach((x) => {
    const [t1, ...t2] = enc.split(x)
    a.push(t1)
    enc = t2.join(x)
    const y = (!x.match(/:\/\//) ? 'https://' : '') + x
    if (isNaN(Number(x)) && isValidHttpUrl(y))
      a.push('<a href="' + y + '" target="_blank" rel="noreferrer">' + y.split('/')[2] + '</a>')
    else a.push(x)
  })
  a.push(enc)
  return a.join('')
}

export const itemToAttributes = (item: IItemAttributes): IAttributeValues => {
  const attributes: IAttributeValues = {
    ['id']: item.id || '',
    ['parentId']: item.parentId || '',
    ['typeId']: item.typeId || '',
    ['contributorId']: item.contributorId || '',
  }
  item.values?.forEach((v) => (attributes[v.attributeId] = v.value))
  // if (item.children?.length) {
  //   attributes['children'] = item.children.map((c) => itemToAttributes(c as IItemAttributes))
  // }
  return attributes
}
