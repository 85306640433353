import { FC, useEffect, useState, Fragment } from 'react'
import { useRecoilValue } from 'recoil'
// @ts-ignore
import debounce from 'promise-debounce'

import { ContributorId, getToken } from '../stores/appStore'
import { getNextRequest, saveResponse } from '../api/request'
import { IRequestAttributes } from '../interfaces/request'
import { IQuestionAttributes } from '../interfaces/question'
import { IResponseAttributes } from '../interfaces/response'
import { IPairAttributes } from '../interfaces/pair'

import OHMLogo from '../components/common/OHMLogo'
import LayoutStatic from '../components/layouts/LayoutGrey'
import Layout from '../components/layouts/LayoutGreyNoPortrait'
import Loading from '../components/common/LoadingMessage'
import ErrorDisplay from '../components/common/ErrorDisplay'
import RatingHeader from './rating/RatingHeader'
import RateItem from './rating/RateItem'
import Instructions from './rating/Instructions'
import { termsAndConditionsAccepted } from '../api/contributor'

import styles from './Rating.module.scss'

const VOTING_SCREEN_MINW = 840
// const VOTING_SCREEN_MINH = 600
// const IS_TOUCH_DEVICE = 'ontouchstart' in window || 'onmsgesturechange' in window ? true : false

// :TODO: make this configurable, allow access to multiple active surveys
const ACTIVE_SURVEY_ID = '8CCC7668-6C0A-45FE-8443-D26E90AA2BDD'

const debouncedNextRequest = debounce(getNextRequest, 100)
//const debouncedNextItem = debounce(getItemById, 100)

const RatingPage: FC = () => {
  // authenticated contributor ID
  const contributorId = useRecoilValue(ContributorId)

  // item pair ratings save state
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  // currently active rating context pulled from server
  const [surveyId, setSurveyId] = useState('')
  const [request, setRequest] = useState<IRequestAttributes | null>(null)
  const [responses, setResponses] = useState<IResponseAttributes[]>([])
  const [pair, setPair] = useState<IPairAttributes[] | null>(null)

  // index of question being answered in the `Request`'s configured questions
  const [activeQuestion, setActiveQuestion] = useState(0)

  // outer dimensions of the page, for layout swapping on narrow screens
  const [dimensions, setDimensions] = useState({ width: -1, height: -1 })

  // Has the viewer clicked "ok" to acknowledge the instructions?
  // :TODO: this could be saved to localStorage so that they never have to acknowledge them again.
  const [instructionsAck, setInstructionsAck] = useState(false)

  /**
   * Notifies the backend that the tac have been accepted.
   */
  async function onTermsAndConditionsClicked() {
    const accessToken = await getToken()
    await termsAndConditionsAccepted(accessToken, contributorId)
    setInstructionsAck(true)
  }

  /**
   * Retrieves whatever access token we have from Auth0 and passes it to Recoil
   *
   * :TODO: check whether this is really necessary, or if everywhere pulls it from Auth0
   */
  async function refreshAccessToken() {
    const accessToken = await getToken()
    return accessToken
  }

  /**
   * Retrieve the next available `request` object for the active survey
   */
  async function fetchNextRequestForSurvey(accessToken: string, survId: string) {
    setSurveyId(survId)
    const req = await debouncedNextRequest(accessToken, survId)
    setupRequest(req)
  }

  const setupRequest = (nextRequest: IRequestAttributes) => {
    setPair(null)
    setError(null)
    setSaving(false)
    setActiveQuestion(0)
    setRequest(nextRequest)

    if (nextRequest === null) {
      // :TODO: Check what is meant to happen when getNextRequest returns null.
      //        Does this mean the survey is over?
      setError(new Error('No request available'))
      return null
    } else {
      setResponses(nextRequest.responses)
      setPair(nextRequest.pairs)
    }
  }

  /**
   * Callback handler for saving responses
   */
  async function saveResponses(responses: IResponseAttributes[]) {
    setSaving(true)
    const accessToken = await getToken()
    const nextRequest = await saveResponse(accessToken, surveyId, responses)
    if (nextRequest) setupRequest(nextRequest)
    else setError(new Error('No request available'))
  }

  /**
   * Generator method to wrap `saveResponses` in logic for dealing with question progression
   */
  function makeVoteHandler(allQuestions: IQuestionAttributes[], activeQIdx: number) {
    return async (responses: IResponseAttributes[]) => {
      // update responses state held in this component each time we submit
      setResponses(responses)

      // When we reach the end of questions, save all responses for this pair.
      // Otherwise we just move to the next question.
      if (activeQIdx === allQuestions.length - 1) {
        try {
          await saveResponses(responses)
        } catch (e) {
          setError(e as Error)
        }
      } else {
        setActiveQuestion(activeQIdx + 1)
      }
    }
  }

  // start request flow when component displays
  useEffect(() => {
    refreshAccessToken()
      .then((accessToken) => {
        //@ts-ignore
        fetchNextRequestForSurvey(accessToken, ACTIVE_SURVEY_ID)
      })
      .catch(setError)
  }, []) //eslint-disable-line

  // helpers for data preparation
  const getQuestions = (req: IRequestAttributes): IQuestionAttributes[] => req.survey.ratings.map((r) => r.question)
  const getActiveQuestion = (qs: IQuestionAttributes[]): IQuestionAttributes => qs[activeQuestion]

  const newResponse = (
    active: boolean,
    requestId: string,
    surveyId: string,
    itemId: string,
    questionId: string,
  ): IResponseAttributes => ({
    itemId: itemId,
    questionId: questionId,
    surveyId: surveyId,
    requestId: requestId,
    value: active ? 1 : 0,
    contributorId,
    timestamp: null,
  })

  const fillResponsesForItemQuestion = (
    responses: IResponseAttributes[],
    requestId: string,
    surveyId: string,
    activeItemId: string,
    inactiveItemId: string,
    questionId: string,
  ): IResponseAttributes[] => {
    const newResponses = [...responses]
    const activeOk = newResponses.find((r) => r.itemId == activeItemId && r.questionId === questionId)
    if (!activeOk) {
      newResponses.push(newResponse(true, requestId, surveyId, activeItemId, questionId))
    }
    const inactiveOk = newResponses.find((r) => r.itemId == inactiveItemId && r.questionId === questionId)
    if (!inactiveOk) {
      newResponses.push(newResponse(false, requestId, surveyId, inactiveItemId, questionId))
    }
    return newResponses
  }

  function formatQuestionText(qStr: string) {
    // add punctuation if not present
    const trimmed = qStr.split(/\s+$/, 2)[0]
    const trailing = trimmed[trimmed.length - 1]
    if (trailing === '?' || trailing === '.' || trailing === '!') {
      return trimmed
    }
    return trimmed + '?'
  }

  // render component
  if (error) {
    return (
      <LayoutStatic>
        <ErrorDisplay message='Error loading survey' error={error} />
      </LayoutStatic>
    )
  }

  // callback to run over `react-measure` computed size
  const displayRotationOverlay = (w: number, h: number) =>
    // if in portrait orientation and smaller than comfortable
    w <= VOTING_SCREEN_MINW && w < h

  // setup Instructions page display based on view state
  const instructionsCopy = instructionsAck ? null : (
    <Fragment>
      <OHMLogo />
      <h1>How to use the app</h1>
      <p>
        WisdOHM estimates the level of crowd-appreciation for different contributions. We do this by showing you two
        contributions at a time and asking you to express love for just one of them, on a certain dimension of interest.
        When a new pair of contributions appears, read through both descriptions so that you have a clear idea about
        each item. Then read the question at the top of the page and select one of the two contributions that you wish
        to 'vote' for. There is no 'skip' button, so if you find it impossible to choose just go with your gut and trust
        in the crowd (you won't be the only one making these choices, so each contribution will have lots of chances to
        be selected).
      </p>
      <p>
        We will ask you a few questions about each pair, before moving onto the next pair. Each set of questions you
        complete will earn you 1 OHM Nom, which represents the community's gratitude for the valuable data you're
        providing here. Complete as many paired comparisons as you like. To close the app, click logout in the menu bar
        or simply close the browser window.
      </p>
      <h2>Terms and conditions</h2>
      <p>
        The Open Heart &amp; Mind (OHM) Community makes all of our materials and data publicly available under open
        source licenses. By clicking Accept below, you agree to making your data available as per the agreements of our
        &nbsp; <a href='https://app.clickup.com/36615879/v/dc/12xdp7-382'>licence</a>.
      </p>
    </Fragment>
  )
  const instructionsPage = (
    <Instructions acknowledged={instructionsAck} onAcknowledge={() => onTermsAndConditionsClicked()}>
      {instructionsCopy}
      {instructionsAck && displayRotationOverlay(dimensions.width, dimensions.height) ? (
        <Fragment>
          <p className={styles.centered}>
            Please rotate your device
            <br />
            or widen the window to continue.
          </p>
          <img src={`/device-rotate.svg`} />
        </Fragment>
      ) : null}
    </Instructions>
  )

  // always render Instructions page if we haven't acknowledged it yet; also allows instructions viewing while loading
  if (!instructionsAck) {
    return (
      <Layout
        lowResLayout={instructionsPage}
        isLowResLayoutAt={displayRotationOverlay}
        onDimensionsUpdate={setDimensions}
      >
        {instructionsPage}
      </Layout>
    )
  }

  if (request && pair?.length === 2) {
    const questions = getQuestions(request)
    const activeQuestionData = getActiveQuestion(questions)

    return (
      // conditionally continue to render Instructions page once accepted if screen too narrow
      <Layout
        lowResLayout={instructionsPage}
        isLowResLayoutAt={displayRotationOverlay}
        onDimensionsUpdate={setDimensions}
      >
        {/* rating controls primary UI elements */}
        <RatingHeader
          question={formatQuestionText(activeQuestionData.description || activeQuestionData.title)}
          totalQuestions={questions.length}
          currentQuestionIdx={activeQuestion}
          isSaving={saving}
          ohmnomsBalance={request.currentOHMNomBalance}
        />

        <div className={styles.choices}>
          <RateItem
            itemId={pair[0].itemId}
            responses={fillResponsesForItemQuestion(
              responses,
              request.id,
              request.surveyId,
              pair[0].itemId,
              pair[1].itemId,
              activeQuestionData.id,
            )}
            onSave={makeVoteHandler(questions, activeQuestion)}
          />
          <RateItem
            itemId={pair[1].itemId}
            responses={fillResponsesForItemQuestion(
              responses,
              request.id,
              request.surveyId,
              pair[1].itemId,
              pair[0].itemId,
              activeQuestionData.id,
            )}
            onSave={makeVoteHandler(questions, activeQuestion)}
          />
        </div>
      </Layout>
    )
  } else {
    return (
      <LayoutStatic>
        <Loading message='Loading Request' />
      </LayoutStatic>
    )
  }
}

export default RatingPage
