import { formatISO, isDate, parseISO } from 'date-fns'
import { useState, useEffect } from 'react'
import { IAttributeValidation } from '../../interfaces/attribute'

export const validate = (validation: IAttributeValidation, value = ''): string => {
  if (!validation) return ''
  switch (validation.type) {
    case 'list':
      if (validation.required && !value?.length) return 'Required'
      if (!validation.list?.find((l) => l.id === value)) return 'Select from list'
      break
    case 'decimal':
    case 'integer':
    case 'duration':
      if (isNaN(Number(value))) return `Expecting ${validation.type} value`
      if (validation.required && !value?.length) return 'Required'
      if (validation.range?.min && Number(value) < validation.range.min) return `Minimum ${validation.range.min}`
      if (validation.range?.max && Number(value) > validation.range.max) return `Maximum ${validation.range.max}`
      if (validation.match && RegExp(validation.match).test(value)) return `Does not match pattern: ${validation.match}`
      break

    case 'datetime':
      if (!isDate(value)) return `Expecting ${validation.type} value`
      if (validation.required && !value?.length) return 'Required'
      if (validation.range?.min && parseISO(value).valueOf() < validation.range.min)
        return `Minumum date ${formatISO(validation.range.min)}`
      if (validation.range?.max && parseISO(value).valueOf() < validation.range.max)
        return `Maximum date ${formatISO(validation.range.max)}`
      break

    default: //string
      if (validation.required && !value?.length) return 'Required'
      if (validation.range?.min && value?.length < validation.range.min) return `Minimum ${validation.range.min} chars`
      if (validation.range?.max && value?.length > validation.range.max) return `Maximum ${validation.range.max} chars`
      if (validation.match && RegExp(validation.match).test(value)) return `Does not match pattern: ${validation.match}`
      break
    //break
  }
  return ''
}

export const useValidation = (
  validation?: IAttributeValidation,
  value?: string,
  focused?: boolean,
  onErrorChanged?: (error: string) => void,
): [boolean, string] => {
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (validation && (!focused || value?.length)) {
      const err = validate(validation, value)
      setErrorMessage(err)
      onErrorChanged && onErrorChanged(err)
    }
  }, [value, validation, focused, onErrorChanged])

  return [!!errorMessage.length, errorMessage]
}

export const useArrayValidation = (
  validation?: IAttributeValidation,
  values?: string[],
  focused?: boolean,
  onErrorChanged?: (error: string) => void,
): [boolean, string] => {
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (validation && !focused) {
      if (validation.required && !values?.length) {
        setErrorMessage('Required')
        onErrorChanged && onErrorChanged('Required')
      } else {
        onErrorChanged && onErrorChanged('')
      }
      // const err = validate(validation, value)
      // //console.log({ focused, err, value, validation })
      // setErrorMessage(err)
    }
  }, [values, validation, focused, onErrorChanged])

  return [!!errorMessage.length, errorMessage]
}
