import { FC } from 'react'
import Layout from './Layout'
import TreeView from '../components/common/TreeView'
import { WISDOHM_ROOT } from '../interfaces/base'

const OrganisationPage: FC = () => {
  return (
    <Layout>
      <TreeView parentId={WISDOHM_ROOT} />
    </Layout>
  )
}

export default OrganisationPage
