import { User } from '@auth0/auth0-spa-js'
import { IContributorAttributes } from '../interfaces/contributor'
import { IDemographicAttributes } from '../interfaces/demographic'

const routerPath = `${process.env.REACT_APP_OHM_API_BASEURL || ''}/api/contributor`

export const getContributors = async (token: string): Promise<IContributorAttributes[] | null> => {
  try {
    const response = await fetch(`${routerPath}/lookup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })
    return response.ok ? response.json() : null
  } catch (error) {
    console.log(error)
    return null
  }
}

export const checkUser = async (token: string, user: User): Promise<IContributorAttributes> => {
  const response = await fetch(`${routerPath}/check`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(user),
  })
  if (response.ok) {
    return response.json()
  }
  throw new Error(response.statusText)
}

export const getProfile = async (token: string, contributorId: string): Promise<IContributorAttributes | null> => {
  const response = await fetch(`${routerPath}/profile/${contributorId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  })
  return response.ok ? response.json() : null
}

export const updateUsername = async (
  token: string,
  email: string,
  username: string,
  demographics?: IDemographicAttributes[],
): Promise<boolean | null> => {
  const response = await fetch(`${routerPath}/username`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ email, username, demographics }),
  })
  return response.ok ? response.json() : null
}

export const updateUserdemographics = async (
  token: string,
  contributorId: string,
  demographics: IDemographicAttributes[],
): Promise<boolean | null> => {
  const response = await fetch(`${routerPath}/demographics/${contributorId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ demographics }),
  })
  return response.ok ? response.json() : null
}

export const termsAndConditionsAccepted = async (token: string, contributorId: string): Promise<boolean | null> => {
  const response = await fetch(`${routerPath}/tac/${contributorId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
  return response.ok ? response.json() : null
}
