import { Checkbox, FormControlLabel } from '@mui/material'
import { formatISO, parseISO } from 'date-fns'
import { FC, useMemo } from 'react'
import { IAttributeAttributes, IAttributeValidation, IAttributeValidationTypes } from '../../interfaces/attribute'

import Style from '../../styles/controls/ValidationEditor.module.sass'
import Datepicker from './Datepicker'
import Dropdown from './Dropdown'
import TextInput from './Textinput'
import AttributeChildren from './AttributeChildren'

interface IValidationEditorProps {
  validation: IAttributeValidation
  attribute: IAttributeAttributes
  onChange?: (value: IAttributeValidation) => void
  onChildreChanged?: (children: IAttributeAttributes[]) => void
}
const ValidationEditor: FC<IValidationEditorProps> = (props) => {
  const { validation, attribute } = props

  const typelist: IAttributeValidationTypes[] = ['string', 'decimal', 'integer', 'datetime', 'duration', 'list']
  const typeOptions = typelist.map((s) => {
    return { id: s, title: s }
  })

  const onTypeChanged = (val: IAttributeValidationTypes) => {
    props.onChange && props.onChange({ ...validation, type: val })
  }
  const onRequiredChanged = (checked: boolean) => {
    props.onChange && props.onChange({ ...validation, required: checked || undefined })
  }

  const validationOptions = useMemo(() => {
    const onMatchChanged = (reg: string) => {
      props.onChange && props.onChange({ ...validation, match: reg })
    }
    const onMinChanged = (val: string) => {
      let num = NaN
      if (validation.type === 'datetime') {
        num = val.length ? parseISO(val).valueOf() : NaN
      } else {
        num = val.length ? Number(val) : NaN
      }
      if (isNaN(num)) {
        if (!validation.range?.max) validation.range = undefined
        else validation.range.min = undefined
      } else {
        if (!validation.range) validation.range = { min: num }
        else validation.range.min = num
      }
      props.onChange && props.onChange({ ...validation })
    }
    const onMaxChanged = (val: string) => {
      let num = NaN
      if (validation.type === 'datetime') {
        num = val.length ? parseISO(val).valueOf() : NaN
      } else {
        num = val.length ? Number(val) : NaN
      }

      if (isNaN(num)) {
        if (!validation.range?.min) validation.range = undefined
        else validation.range.max = undefined
      } else {
        if (!validation.range) validation.range = { max: num }
        else validation.range.max = num
      }

      props.onChange && props.onChange({ ...validation })
    }
    switch (validation.type) {
      case 'datetime':
        return (
          <>
            <div className={Style.minMax}>
              <Datepicker
                onValueChanged={(val) => onMinChanged(val ? formatISO(val) : '')}
                label='Min'
                value={(validation.range?.min || '').toString()}
                validation={{ type: 'datetime', range: { max: validation.range?.max || undefined } }}
              ></Datepicker>{' '}
              <Datepicker
                onValueChanged={(val) => onMaxChanged(val ? formatISO(val) : '')}
                label='Max'
                value={(validation.range?.max || '').toString()}
                validation={{ type: 'datetime', range: { min: validation.range?.min || undefined } }}
              ></Datepicker>
            </div>
            <TextInput onValueChanged={onMatchChanged} value={validation.match} label='Regex'></TextInput>
          </>
        )
      case 'list':
        return (
          <AttributeChildren
            attribute={attribute}
            label='Add Item'
            onChildrenChanged={(children) => props.onChildreChanged && props.onChildreChanged(children)}
          ></AttributeChildren>
        )

      default:
        return (
          <>
            <div className={Style.minMax}>
              <TextInput
                onValueChanged={onMinChanged}
                label='Min'
                value={(validation.range?.min || '').toString()}
                validation={{ type: 'integer', match: '/^[0-9]*/g' }}
              ></TextInput>{' '}
              <TextInput
                onValueChanged={onMaxChanged}
                label='Max'
                value={(validation.range?.max || '').toString()}
                validation={{ type: 'integer', match: '/^[0-9]*/g' }}
              ></TextInput>
            </div>
            <TextInput onValueChanged={onMatchChanged} value={validation.match} label='Regex'></TextInput>
          </>
        )
    }
  }, [attribute, props, validation])

  return (
    <div className={Style.wrapper}>
      <FormControlLabel
        className={Style.required}
        control={
          <Checkbox checked={validation.required || false} onChange={(event, checked) => onRequiredChanged(checked)} />
        }
        label='Required'
      />
      <Dropdown
        label='Validate as'
        value={validation.type}
        options={typeOptions}
        onValueChanged={(val) => onTypeChanged(val as IAttributeValidationTypes)}
      ></Dropdown>
      {validationOptions}
    </div>
  )
}

export default ValidationEditor
