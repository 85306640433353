import { atom, selector, selectorFamily } from 'recoil'
import { getContributors, getProfile } from '../api/contributor'
import { getItemById, getItemLookup } from '../api/item'
import { getTemplateById, getTemplatesLookup } from '../api/template'
import { getTypesLookup } from '../api/type'
import { ContributorRoleEnum } from '../enums/ContributorRoleEnum'
import { IBaseTitle } from '../interfaces/base'
import { IContributorAttributes } from '../interfaces/contributor'
import { IItemAttributes } from '../interfaces/item'
import { ITemplateAttributes } from '../interfaces/template'
import { ITypeAttributes } from '../interfaces/type'
import { TokenManager } from '../utils/TokenManager'

export const getToken = async (scopes?: string) => {
  const tokenManager = await TokenManager()
  let token = await tokenManager.requestToken(scopes || '')
  const expired = tokenManager.checkTokenExpiration(token)

  if (expired) {
    try {
      tokenManager.handleTokenExpiry()
      token = await tokenManager.requestToken(scopes || '')
    } catch (err) {
      return ''
    }
  }
  return token
}

export const ContributorId = atom<string>({
  key: 'contributorId',
  default: '',
})

export const ContributorRole = atom<ContributorRoleEnum>({
  key: 'contributorRole',
  default: ContributorRoleEnum.Reviewer,
})

export const ItemLookup = selector({
  key: 'item_lookup',
  get: async (): Promise<IItemAttributes[] | null> => {
    const accessToken = await getToken()
    if (!accessToken) return null
    return await getItemLookup(accessToken)
  },
})

export const ItemById = selectorFamily({
  key: 'item_family',
  get: (itemId: string | null) => async (): Promise<IItemAttributes | null> => {
    if (!itemId) {
      return null
    }
    const accessToken = await getToken()
    if (!accessToken) return null
    const item = await getItemById(accessToken, itemId)
    return item
  },
})

export const TypeLookup = selector({
  key: 'type_lookup',
  get: async (): Promise<ITypeAttributes[] | null> => {
    const accessToken = await getToken()
    if (!accessToken) return null
    const types = await getTypesLookup(accessToken)
    return types
  },
})

export const TemplateById = selectorFamily({
  key: 'template_family',
  get: (templateId: string | null) => async (): Promise<ITemplateAttributes | null> => {
    if (!templateId) {
      return null
    }
    const accessToken = await getToken()
    if (!accessToken) return null
    const template = await getTemplateById(accessToken, templateId)
    return template
  },
})

export const TemplateLookup = selector({
  key: 'template_lookup',
  get: async (): Promise<ITemplateAttributes[] | null> => {
    const accessToken = await getToken()
    if (!accessToken) return null
    const templates = await getTemplatesLookup(accessToken)
    return templates
  },
})

export const ContributorLookup = selector({
  key: 'contributor_lookup',
  get: async (): Promise<IBaseTitle[] | null> => {
    const accessToken = await getToken()
    if (!accessToken) return null
    const contribs = await getContributors(accessToken)
    return (
      contribs?.map((c) => {
        return { id: c.id, title: c.contributorName }
      }) || null
    )
  },
})

export const Profile = selector({
  key: 'profile',
  get: async ({ get }): Promise<IContributorAttributes | null> => {
    const accessToken = await getToken()
    if (!accessToken) return null
    const contributorId = get(ContributorId)
    const profile = await getProfile(accessToken, contributorId)
    return profile
  },
})

export const RequestItems = selectorFamily({
  key: 'request_items',
  get: (itemId: string | null) => async (): Promise<IItemAttributes | null> => {
    if (!itemId) {
      return null
    }
    const accessToken = await getToken()
    if (!accessToken) return null
    const item = await getItemById(accessToken, itemId)
    return item
  },
})
