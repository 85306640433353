import { Box, CircularProgress } from '@mui/material'
import { FC } from 'react'

//import Style from '../styles/Icon.module.sass'

export interface ILoadingProps {
  message?: string
}
const Loading: FC<ILoadingProps> = (props) => {
  const { message = 'loading' } = props
  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box component='div' sx={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end', p: '12px' }}>
        {message}
      </Box>
      <Box component='div' sx={{ flexGrow: 1, alignItems: 'flex-start', p: '12px' }}>
        <CircularProgress />
      </Box>
    </Box>
  )
}

export default Loading
