import { IAttributeValues } from '../interfaces/attribute'
import { IItemAttributes } from '../interfaces/item'

const routerPath = `${process.env.REACT_APP_OHM_API_BASEURL || ''}/api/item`

export const getItems = async (token: string): Promise<IItemAttributes[] | null> => {
  const response = await fetch(`${routerPath}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  })
  return response.ok ? response.json() : null
}

export const getItemById = async (token: string, id: string): Promise<IItemAttributes> => {
  const response = await fetch(`${routerPath}/item/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  })
  if (response.ok) return response.json()
  throw new Error(response.statusText)
}

export const getItemLookup = async (token: string): Promise<IItemAttributes[] | null> => {
  const response = await fetch(`${routerPath}/lookup`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  })
  return response.ok ? response.json() : null
}

export const getItemHierarchy = async (
  token: string,
  parentId?: string,
  depth = 2,
): Promise<IItemAttributes[] | null> => {
  const response = await fetch(`${routerPath}/hierarchy/${depth}/${parentId || ''}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  })
  return response.ok ? response.json() : null
}

export const saveItem = async (
  token: string,
  item: IAttributeValues,
  children: IAttributeValues[],
): Promise<IItemAttributes | null> => {
  const response = await fetch(`${routerPath}/add`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ item, children }),
  })
  return response.ok ? response.json() : null
}
