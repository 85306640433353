import { FC } from 'react'

//import Style from '../styles/pages/TemplatePage.module.sass'
import AddTemplate from './template/AddTemplate'
import Layout from './Layout'

const TemplatePage: FC = () => {
  return (
    <Layout>
      <AddTemplate />
    </Layout>
  )
}

export default TemplatePage
