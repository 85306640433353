import {
  FormControl,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { IAttributeValidation } from '../../interfaces/attribute'
import { IBaseTitle, WISDOHM_ROOT } from '../../interfaces/base'
import { isITypeAttribute } from '../../interfaces/type'

import Style from '../../styles/controls/Controls.module.sass'
import { useArrayValidation } from '../hooks/useValidation'

interface IListInputProps {
  values?: string[]
  label?: string
  validation?: IAttributeValidation
  options: IBaseTitle[]
  tooltip?: string
  onValueChanged: (val: string[]) => void
  onErrorChanged?: (error: string) => void
}

const ListInput: FC<IListInputProps> = (props) => {
  const { values, label, validation, tooltip, onErrorChanged } = props
  const [focused, setFocused] = useState(true)
  const [loading, setLoading] = useState(true)
  const [error, errorMessage] = useArrayValidation(validation, values, focused, onErrorChanged)

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  // eslint-disable-next-line
  const handleChange = (event: SelectChangeEvent<any>, child: React.ReactNode) => {
    console.log(event.target.value)
    props.onValueChanged(event.target.value)
  }

  const listinputOptions = useMemo(() => {
    if (!props.options.length) {
      setLoading(true)
      return [{ id: WISDOHM_ROOT, title: 'loading', icon: '' }]
    }
    setLoading(false)
    return props.options.map((o) => {
      return { ...o, icon: isITypeAttribute(o) ? o.icon : 'star' }
    })
  }, [props.options])
  console.log({ values })
  return (
    <div className={Style.wrapper}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <div className={Style.inputIcon}>
          <Select
            fullWidth
            multiple
            required={validation?.required}
            error={error}
            value={!loading ? values : [WISDOHM_ROOT]}
            label={props.label}
            autoComplete='off'
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            {listinputOptions.map((o) => {
              return (
                <MenuItem key={o.id} value={o.id}>
                  <Icon>{o.icon}</Icon>
                  <span>{o.title}</span>
                </MenuItem>
              )
            })}
          </Select>
          {tooltip && (
            <Tooltip title={tooltip}>
              <Icon>help_centre</Icon>
            </Tooltip>
          )}
        </div>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    </div>
  )
}

export default ListInput
