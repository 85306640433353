/**
 * Grey layout (2023 redesign)
 *
 * Manages a large array of display issues by progressively magnifying
 * its contents as layout widens. Scaling is based on `em` and element font
 * sizes- to take advantage of this feature ensure that all scaling display
 * elements present inside this component have their dimensions declared in `em`.
 * @see LayoutGrey.module.scss
 */
import { FC, ReactNode } from 'react'

import styles from './LayoutGrey.module.scss'

interface ILayoutProps {
  children?: ReactNode
}

const LayoutGrey: FC<ILayoutProps> = (props) => {
  return <div className={styles.pageWrap}>{props.children}</div>
}

export default LayoutGrey
