import { ITypeAttributes } from '../interfaces/type'

const routerPath = `${process.env.REACT_APP_OHM_API_BASEURL || ''}/api/type`

export const getTypes = async (token: string): Promise<ITypeAttributes[] | null> => {
  try {
    const response = await fetch(`${routerPath}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })
    return response.ok ? response.json() : null
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getTypesLookup = async (token: string): Promise<ITypeAttributes[] | null> => {
  try {
    const response = await fetch(`${routerPath}/lookup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })
    return response.ok ? response.json() : null
  } catch (error) {
    console.log(error)
    return null
  }
}
