import { FC } from 'react'
import { IItemAttributes } from '../../../interfaces/item'
import ItemRow from '../ItemRow/ItemRow'
import Style from './ItemList.module.scss'

interface IItemListProps {
  items: IItemAttributes[] | null
}

const ItemList: FC<IItemListProps> = (props) => {
  const { items } = props
  console.log(items)
  return items ? (
    <div className={Style.list}>
      {items.map((item) => (
        <ItemRow key={item.id} item={item}></ItemRow>
      ))}
    </div>
  ) : (
    <p>No Items found</p>
  )
}

export default ItemList
