import { useEffect, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { TemplateById } from '../../stores/appStore'
import { ITemplateAttributes } from '../../interfaces/template'

export const useTemplate = (templateId?: string | null): ITemplateAttributes | null => {
  const [template, setTemplate] = useState<ITemplateAttributes | null>(null)
  const loadableTemplate = useRecoilValueLoadable(TemplateById(templateId || null))
  useEffect(() => {
    if (loadableTemplate.state !== 'hasValue' || !loadableTemplate.contents) return
    setTemplate(loadableTemplate.contents)
  }, [loadableTemplate.contents, loadableTemplate.state])

  return template
}
