import { Icon, TextField, Tooltip } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FC, useState } from 'react'
import { IAttributeValidation } from '../../interfaces/attribute'
import { useValidation } from '../hooks/useValidation'
import Style from '../../styles/controls/Controls.module.sass'

interface IDatepickerProps {
  value?: string
  label?: string
  validation?: IAttributeValidation
  tooltip?: string
  readonly?: boolean
  onValueChanged: (val: Date | null) => void
  onErrorChanged?: (error: string) => void
}
const Datepicker: FC<IDatepickerProps> = (props) => {
  const { value, label, validation, tooltip, readonly, onErrorChanged } = props
  const [focused, setFocused] = useState(true)
  const [error, errorMessage] = useValidation(validation, value, focused, onErrorChanged)

  // eslint-disable-next-line
  const handleChange = (newValue: Date | null) => {
    props.onValueChanged(newValue || null)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={Style.wrapper}>
        {/* <FormControl fullWidth>
          <InputLabel>{label}</InputLabel> */}
        <div className={Style.inputIcon}>
          <DatePicker
            inputFormat='dd/MM/yyyy'
            label={label}
            renderInput={(params) => (
              <TextField
                {...params}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                autoComplete='off'
                fullWidth
                label={label}
                required={validation?.required}
                error={error}
                helperText={errorMessage}
              />
            )}
            value={(props.value && new Date(props.value)) || null}
            onChange={(newValue) => handleChange(newValue)}
            readOnly={readonly}
          />
          {tooltip && (
            <Tooltip title={tooltip}>
              <Icon>help_centre</Icon>
            </Tooltip>
          )}
        </div>
        {/* <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl> */}
      </div>
    </LocalizationProvider>
  )
}

export default Datepicker
