import { useEffect, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { ItemById } from '../../stores/appStore'
import { IItemAttributes } from '../../interfaces/item'

export const useItem = (itemId?: string | null): IItemAttributes | null => {
  const [item, setItem] = useState<IItemAttributes | null>(null)
  const loadableItem = useRecoilValueLoadable(ItemById(itemId || null))
  useEffect(() => {
    if (loadableItem.state !== 'hasValue' || !loadableItem.contents) return
    setItem(loadableItem.contents)
  }, [loadableItem.contents, loadableItem.state])

  return item
}
