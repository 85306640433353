import { ITemplateAttributes } from './template'
import { ITypeAttributes } from './type'
import { IValueAttributes } from './value'

import { IBaseId, IBaseTitle, IBaseType, IBaseAudit, IBaseSelfRefAttributes } from './base'
export interface IAttributeAttributes
  extends IBaseId,
    IBaseType,
    IBaseTitle,
    IBaseAudit,
    IBaseSelfRefAttributes<IAttributeAttributes> {
  templateId: string
  description?: string
  validation?: string
  sortOrder: number
  placement: AttributePlacement

  values?: IValueAttributes[]
  template?: ITemplateAttributes
  type?: ITypeAttributes
}
// id: string
// title: string
// typeId: string
// contributorId: string
// timestamp: number

export type IAttributeValidationTypes = 'string' | 'decimal' | 'integer' | 'datetime' | 'duration' | 'list'
export interface IAttributeValidation {
  type?: IAttributeValidationTypes
  required?: boolean
  range?: { min?: number; max?: number }
  match?: string
  list?: IBaseTitle[]
}

export enum AttributePlacement {
  none = 0,
  heading = 1,
  centre = 2,
  row = 3,
}

export interface IAttributeValues {
  [id: string]: string
}
