import { FC, useEffect, useState } from 'react'

import AddItem from './submission/AddItem'
import { useAuth0 } from '@auth0/auth0-react'
import Dropdown from '../components/common/Dropdown'

import { getOpenSubmissions } from '../api/submission'
import { ISubmissionAttributes } from '../interfaces/submission'
import Layout from './Layout'

const SubmissionPage: FC = () => {
  const [selected, setSelected] = useState<ISubmissionAttributes>()
  const [loading, setLoading] = useState(false)

  const [submissions, setSubmissions] = useState<ISubmissionAttributes[]>()
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (loading) return

    const effectAsync = async () => {
      if (loading) return
      setLoading(true)
      const accessToken = await getAccessTokenSilently()
      const submissionlist = await getOpenSubmissions(accessToken)
      if (submissionlist) {
        setSubmissions(submissionlist)
      }

      setLoading(false)
    }

    effectAsync()
  }, []) //eslint-disable-line

  const onSubmissionSelected = (val: string) => {
    if (!submissions) return
    const submission = submissions.find((i) => i.id == val)
    setSelected(submission)
  }

  const onSaved = () => {
    setSelected(undefined)
  }
  return (
    <Layout>
      {!selected && <h3>Make a submission</h3>}
      {submissions && !selected && (
        <Dropdown options={submissions} onValueChanged={onSubmissionSelected} label='Submission Type'></Dropdown>
      )}
      {selected && <AddItem submission={selected} onSaved={onSaved}></AddItem>}
    </Layout>
  )
}

export default SubmissionPage
