import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  Icon,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { SystemTypeEnum } from '../../enums/SystemTypeEnum'
import { WISDOHM_ROOT } from '../../interfaces/base'
import { ITypeAttributes } from '../../interfaces/type'

import Style from '../../styles/controls/Controls.module.sass'
import { useTypeHierarchy } from '../hooks/useTypeHierarchy'

interface ITypeListProps {
  value: ITypeAttributes | null
  label?: string
  systemType?: SystemTypeEnum | string
  tooltip?: string
  onValueChanged: (type: ITypeAttributes) => void
  readonly?: boolean
  //onErrorChanged?: (error: string) => void
}

const TypeList: FC<ITypeListProps> = (props) => {
  const { value, label, tooltip, systemType, readonly } = props
  const [loading, setLoading] = useState(true)

  const filter = createFilterOptions<ITypeAttributes>()

  const type = useTypeHierarchy(systemType || SystemTypeEnum.WisdohmRoot)

  const loadingItem = { id: WISDOHM_ROOT, title: 'loading', icon: '' } as ITypeAttributes
  const dropdownOptions = useMemo(() => {
    if (!type) {
      setLoading(true)
      return [loadingItem] as ITypeAttributes[]
    }
    setLoading(false)
    return (type.children as ITypeAttributes[])?.map((o) => {
      return { ...o, icon: o.icon || 'star' }
    })
  }, [systemType, type]) // eslint-disable-line

  const createAddValue = (newValue: string) => {
    return {
      id: '',
      title: `Add "${newValue}"`,
      icon: '',
      contributorId: '',
      timestamp: new Date().valueOf(),
    }
  }
  return (
    <div className={Style.wrapper}>
      <FormControl fullWidth>
        <div className={Style.inputIcon}>
          <Autocomplete
            fullWidth
            value={loading ? loadingItem : value}
            isOptionEqualToValue={(option, value) => {
              return option.title.toLowerCase() === value.title.toLowerCase()
            }}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                props.onValueChanged(createAddValue(newValue))
              } else if (newValue && !newValue.id.length) {
                // Create a new value from the user input
                props.onValueChanged(newValue)
              } else if (newValue) {
                props.onValueChanged(newValue)
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)
              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue.toLowerCase() === option.title.toLowerCase())
              if (inputValue !== '' && !isExisting) {
                filtered.push(createAddValue(inputValue))
              }
              return filtered
            }}
            selectOnFocus
            readOnly={readonly}
            clearOnBlur
            handleHomeEndKeys
            options={dropdownOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option
              }
              // Regular option
              return option.title
            }}
            renderOption={(props, option) => (
              <MenuItem key={option.id} value={option.id} {...props}>
                <Icon style={{ marginLeft: '6px' }}>{option.icon}</Icon>
                <span style={{ marginLeft: '12px' }}>{option.title}</span>
              </MenuItem>
            )}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon>{value ? value?.icon || 'star' : ''}</Icon>
                    </InputAdornment>
                  ),
                  readOnly: readonly,
                }}
                label={label}
              />
            )}
          />

          {tooltip && (
            <Tooltip title={tooltip}>
              <Icon>help_centre</Icon>
            </Tooltip>
          )}
        </div>
      </FormControl>
    </div>
  )
}

export default TypeList
