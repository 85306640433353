import { FC, useCallback } from 'react'

import { IBaseTitle } from '../../../interfaces/base'
import { IItemAttributes } from '../../../interfaces/item'
import { AttributePlacement, IAttributeAttributes, IAttributeValidation } from '../../../interfaces/attribute'

import Loading from '../../../components/common/LoadingSpinner/LoadingSpinner'
import Button from '../VoteButton/VoteButton'

import { escapeHTML, linkify, sortAttributes } from '../../../utils/utils'
import { useContributorLookup } from '../../../components/hooks/useContributorLookup'

import styles from './Item.module.scss'

export interface IProps {
  item?: IItemAttributes
  attributes?: IAttributeAttributes[]
  voteClicked?: () => void
  isSaving?: boolean
}
const Item: FC<IProps> = (props) => {
  const { item, attributes, isSaving } = props

  const contributors = useContributorLookup()

  // helpers for data preparation
  const getContributor = useCallback((id: string) => contributors?.find((l: IBaseTitle) => l.id === id), [contributors])

  const getItemAttrVal = (item: IItemAttributes, attr: IAttributeAttributes, escape?: boolean) => {
    // :TODO: handle misconfiguration errors where expected attributes don't exist
    let attrVal = item.values?.find((i) => i.attributeId === attr.id)?.value || ''

    const valid: IAttributeValidation = !attr.validation ? {} : JSON.parse(attr.validation)

    if (valid?.type === 'duration') attrVal = `${attrVal} min`

    if (attr.children?.length) {
      attrVal = (attr.children as IAttributeAttributes[]).find((c) => c.id == attrVal)?.title || 'invalid'
    }
    if (escape) {
      if (valid?.type === 'string') {
        attrVal = linkify(attrVal)
      } else {
        attrVal = escapeHTML(attrVal)
      }
    }

    return attrVal
  }

  // bail early and render loading indicator if no data
  if (!contributors || !attributes || !item) {
    return (
      <section className={styles.wrap}>
        <p>
          <Loading size='5em' />
        </p>
      </section>
    )
  }

  const contributor = getContributor(item.contributorId)

  const vote = () => {
    props.voteClicked && props.voteClicked()
  }

  return (
    <section className={styles.wrap}>
      <header>
        <h2>{item.title}</h2>
        <h3>{contributor ? contributor.title : '(unknown contributor)'}</h3>
        {attributes &&
          attributes
            .filter((a) => a.placement === AttributePlacement.heading && getItemAttrVal(item, a)?.length)
            .sort(sortAttributes)
            .map((attr) => <div key={attr.id}>{getItemAttrVal(item, attr)}</div>)}
        <div className={styles.meta}>
          {attributes &&
            attributes
              .filter((a) => a.placement === AttributePlacement.row && getItemAttrVal(item, a)?.length)
              .sort(sortAttributes)
              .map((attr) => <h4 key={attr.id}>{getItemAttrVal(item, attr)}</h4>)}
        </div>
      </header>
      {attributes &&
        attributes
          .filter((a) => a.placement === AttributePlacement.centre && getItemAttrVal(item, a)?.length)
          .sort(sortAttributes)
          .map((attr) => (
            <p
              key={attr.id}
              dangerouslySetInnerHTML={{
                __html: `<b>${escapeHTML(attr.title)}:</b> ${getItemAttrVal(item, attr, true)}`,
              }}
            ></p>
          ))}
      {item.externalId && (
        <a
          className={styles.externalLink}
          href={`https://app.clickup.com/t/${item.externalId}`}
          rel='noreferrer'
          target='_blank'
        >
          Open Clickup Task
        </a>
      )}

      <footer className={styles.vote}>
        <Button onClick={() => vote()} disabled={isSaving}>
          Vote
        </Button>
      </footer>
    </section>
  )
}

export default Item
