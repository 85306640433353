import { FC } from 'react'
import { CircularProgress } from '@mui/material'

export interface IProps {
  size?: string
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit'
}

const Loading: FC<IProps> = (props) => <CircularProgress color='primary' {...props} />

export default Loading
