import { FC, useEffect, useState } from 'react'

import { createHierarchy } from '../../../interfaces/base'
import { IAttributeAttributes } from '../../../interfaces/attribute'
import { IResponseAttributes } from '../../../interfaces/response'

import Loading from '../../../components/common/LoadingSpinner'

import styles from './RateItem.module.scss'
import Item from '../Item/Item'
import { useTemplate } from '../../../components/hooks/useTemplate'
import { useItem } from '../../../components/hooks/useItem'
import { useContributorLookup } from '../../../components/hooks/useContributorLookup'

export interface IProps {
  itemId: string
  responses?: IResponseAttributes[]
  isSaving?: boolean
  onSave: (responses: IResponseAttributes[]) => Promise<void>
}
const RateItem: FC<IProps> = (props) => {
  const { itemId, responses, isSaving, onSave } = props

  const [attributes, setAttributes] = useState<IAttributeAttributes[] | null>(null)

  const contributors = useContributorLookup()
  const item = useItem(itemId)
  const template = useTemplate(item?.values && item.values.length ? item.values[0].attribute?.templateId || null : null)

  useEffect(() => {
    if (!template?.attributes) return
    setAttributes(
      createHierarchy(
        template.attributes.map((a) => ({ ...a })),
        { sort: { field: 'sortOrder' } },
      ),
    )
  }, [template])

  if (!contributors || !attributes || !item || !responses) {
    return (
      <section className={styles.wrap}>
        <p>
          <Loading size='5em' />
        </p>
      </section>
    )
  }

  return <Item attributes={attributes} isSaving={isSaving} item={item} voteClicked={() => onSave(responses)}></Item>
}

export default RateItem
