import { FC } from 'react'
import { CircularProgress } from '@mui/material'

import styles from './LoadingMessage.module.scss'

export interface ILoadingProps {
  message?: string
}
const Loading: FC<ILoadingProps> = (props) => {
  const { message = 'loading' } = props
  return (
    <div className={styles.outer}>
      <div className={styles.message}>{message}</div>
      <div className={styles.spin}>
        <CircularProgress />
      </div>
    </div>
  )
}

export default Loading
