import { FC, ReactNode } from 'react'

import { IItemAttributes } from '../interfaces/item'
import Header from '../components/common/Header'

import styles from './Layout.module.scss'

export interface ILayoutProps {
  children?: ReactNode
  onItemSelected?: (item: IItemAttributes) => void
  header?: JSX.Element
}

const Layout: FC<ILayoutProps> = (props) => {
  return (
    <div className={styles.pageWrap}>
      {props.header ? props.header : <Header />}
      <div className={styles.layout}>{props.children}</div>
    </div>
  )
}

export default Layout
