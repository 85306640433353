// Button style for rating page vote buttons
import { FC, ReactNode, MouseEventHandler, MouseEvent } from 'react'

import styles from './Button.module.scss'

export interface IProps {
  onClick: MouseEventHandler<HTMLAnchorElement>
  children?: ReactNode
  disabled?: boolean
}
const VoteButon: FC<IProps> = (props) => {
  const { onClick, children, disabled } = props
  return (
    <a
      className={styles.btn + (disabled ? ` ${styles.disabled}` : ``)}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        if (disabled) {
          e.preventDefault()
          e.stopPropagation()
          return
        }
        return onClick(e)
      }}
    >
      {children}
    </a>
  )
}

export default VoteButon
