import { useEffect, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { IBaseTitle } from '../../interfaces/base'
import { ContributorLookup } from '../../stores/appStore'

export const useContributorLookup = (): IBaseTitle[] | null => {
  const [contributors, setContributors] = useState<IBaseTitle[] | null>(null)

  const loadableContributors = useRecoilValueLoadable(ContributorLookup)
  useEffect(() => {
    if (loadableContributors.state !== 'hasValue' || !loadableContributors.contents) return
    setContributors(loadableContributors.contents)
  }, [loadableContributors.contents, loadableContributors.state])

  return contributors
}
