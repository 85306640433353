import { FC, useEffect, useMemo, useState } from 'react'

import { v4 as guid } from 'uuid'

import Dropdown from '../../components/common/Dropdown'
import { ITemplateAttributes } from '../../interfaces/template'
import { saveTemplate } from '../../api/template'
import TextInput from '../../components/common/Textinput'
import { AttributePlacement, IAttributeAttributes, IAttributeValues } from '../../interfaces/attribute'

import { Button } from '@mui/material'
import AddAttribute from './AddAttribute'

import { IItemAttributes } from '../../interfaces/item'
import { IValueAttributes } from '../../interfaces/value'
import { sortAttributes } from '../../utils/utils'

import Item from '../../pages/rating/Item/Item'
import { useTypeHierarchy } from '../../components/hooks/useTypeHierarchy'
import { SystemTypeEnum } from '../../enums/SystemTypeEnum'
import { useContributorLookup } from '../../components/hooks/useContributorLookup'
import { ContributorRole, getToken } from '../../stores/appStore'

import Style from './Template.module.sass'
import { useRecoilValue } from 'recoil'
import { ContributorRoleEnum } from '../../enums/ContributorRoleEnum'
import AttributeControl from '../../components/attributes/AttributeControl'
import { ITypeAttributes } from '../../interfaces/type'

interface ITemplateProps {
  parentId?: string
}

const AddTemplate: FC<ITemplateProps> = () => {
  const [title, setTitle] = useState<string>()
  const [typeId, setTypeId] = useState<string>()
  const [attributes, setAttributes] = useState<IAttributeAttributes[]>([])
  const [contributorId, setContributorId] = useState<string>()

  const [values, setValues] = useState<IAttributeValues>({})

  //const [errors, setErrors] = useState<IAttributeValues>({})
  const [editingAttribute, setEditingAttribute] = useState<string>()
  const [reload, setReload] = useState<boolean>(false)

  const itemType = useTypeHierarchy(SystemTypeEnum.Items)
  const contributors = useContributorLookup()
  const contributorRole = useRecoilValue(ContributorRole)

  const [types, setTypes] = useState<ITypeAttributes[]>()
  const attributeType = useTypeHierarchy(SystemTypeEnum.Attributes)

  useEffect(() => {
    if (!attributeType?.children?.length) return
    setTypes(attributeType.children)
  }, [attributeType])

  const onTypeChanged = (val: string) => {
    setValues((current) => {
      return { ...current, ['typeId']: val }
    })
    setTypeId(val)
  }

  const onTitleChanged = (val: string) => {
    setValues((current) => {
      return { ...current, ['title']: val }
    })
    setTitle(val)
  }

  const onContributorChanged = (val: string) => {
    setValues((current) => {
      return { ...current, ['contributorId']: val }
    })

    setContributorId(val)
  }

  const onSampleValueChanged = (attId: string, val: string) => {
    setValues((current) => {
      return { ...current, [attId]: val }
    })
  }

  const invalid = useMemo(() => {
    //if (!template) return true
    //let errs = errors
    // attributes.forEach((attr) => {
    //   const value = values[attr.id]
    //   const valid: IAttributeValidation = !attr.validation ? {} : JSON.parse(attr.validation)
    //   if (typeof value === 'string') errs = { ...errs, [attr.id]: validate(valid, value) }
    // })
    // console.log(errs)
    // setErrors(errs)
    //return attributes.some((a) => errs[a.id]?.length)
    return false
  }, [values, attributes]) // eslint-disable-line

  const getTemplate = () => {
    return {
      id: '',
      typeId: values['typeId'],
      title: values['title'],
      timestamp: 0,
      contributorId: values['contributorId'],
    }
  }

  const saveClicked = async () => {
    const accessToken = await getToken()
    const template: ITemplateAttributes = getTemplate()
    saveTemplate(accessToken, template, attributes)
  }

  const onValuesChanged = (attr: IAttributeAttributes, values: IAttributeValues, children: IAttributeAttributes[]) => {
    setAttributes((value) => {
      const newAttr: IAttributeAttributes = { ...attr, ...values, children: children as IAttributeAttributes[] }
      console.log(newAttr)
      return [...value.filter((v) => v.id != newAttr.id), newAttr].sort(sortAttributes)
    })
    setReload((val) => !val)
  }
  const addAttribute = () => {
    const newAttribute: IAttributeAttributes = {
      id: guid(),
      description: '',
      parentId: '',
      sortOrder: attributes.length,
      placement: AttributePlacement.centre,
      templateId: '',
      title: '',
      typeId: '',
      contributorId: '',
      timestamp: 0,
      children: [],
    }
    setAttributes((value) => [...value, newAttribute])
    setEditingAttribute(newAttribute.id)
  }

  const itemFromAttributes: IItemAttributes = useMemo(() => {
    return {
      contributorId: contributorId || '',
      title: 'Sample Item Title',
      values: attributes.map((a) => ({ attributeId: a.id, value: `sample value for ${a.title}` } as IValueAttributes)),
      id: values['id'],
      actual: true,
      typeId: values['typeId'],
      timestamp: new Date().valueOf(),
    }
  }, [attributes, contributorId, values])

  return (
    <div className={Style.dualColumn}>
      <div className={Style.scroller}>
        <h1>Add Template</h1>
        <div className={Style.row}>
          <Dropdown
            validation={{ required: true }}
            label='Type'
            value={typeId}
            options={itemType?.children || []}
            onValueChanged={onTypeChanged}
          />
        </div>
        <div className={Style.row}>
          <TextInput
            label='Title'
            validation={{ required: true, range: { max: 150 } }}
            value={title}
            onValueChanged={onTitleChanged}
          />
        </div>

        <div>
          <h2>attributes</h2>
          {attributes.map((attr) => (
            <div key={attr.id} className={Style.row}>
              {editingAttribute === attr.id ? (
                <AddAttribute
                  attribute={attr}
                  onValuesChanged={(values, children) => onValuesChanged(attr, values, children)}
                ></AddAttribute>
              ) : (
                <>
                  <AttributeControl
                    attribute={attr}
                    value={values[attr.id]}
                    onValueChanged={(val) => onSampleValueChanged(attr.id, val)}
                    type={types?.find((t) => t.id == attr.typeId)?.title || ''}
                    reload={reload}
                  ></AttributeControl>
                  <Button
                    variant='outlined'
                    style={{ width: '150px', height: '30px' }}
                    onClick={() => setEditingAttribute(attr.id)}
                  >
                    edit
                  </Button>
                </>
              )}
            </div>
          ))}
          <div className={Style.row}>
            <Button variant='outlined' onClick={addAttribute}>
              Add attribute
            </Button>
          </div>
        </div>
        <div className={Style.row}>
          <Dropdown
            label='Contributor'
            validation={{ required: true }}
            value={contributorId}
            options={contributors || []}
            onValueChanged={onContributorChanged}
            disabled={contributorRole !== ContributorRoleEnum.Manager}
          />
        </div>

        <div className={Style.row}>
          <Button variant='outlined' disabled={invalid} onClick={saveClicked}>
            save
          </Button>
        </div>
      </div>
      <div className={Style.scroller}>
        <h3>Preview Item</h3>
        <Item attributes={attributes} item={itemFromAttributes}></Item>
      </div>
    </div>
  )
}

export default AddTemplate
