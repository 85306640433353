import React, { ReactNode, Component } from 'react'
// @ts-ignore
import Measure, { Dimensions, BoundingRect } from 'react-measure'

import LayoutGrey from '../LayoutGrey'

interface ILayoutProps {
  children?: ReactNode
  // contentRect?: ContentRect
  // measureRef?: React.Ref<Element> | undefined
  // measure?: Dimensions
  // eslint-disable-next-line
  lowResLayout?: ReactNode
  isLowResLayoutAt?: (w: number, h: number) => boolean
  onDimensionsUpdate?: (dims: BoundingRect) => void
}

export interface MeasuredElementProps {
  size?: Dimensions
}

class LayoutGreyNoPortrait extends Component {
  state = {
    dimensions: {
      width: -1,
      height: -1,
    },
  }
  props: ILayoutProps = {}

  constructor(props: ILayoutProps) {
    super(props)
    this.props = props
  }

  render() {
    const { width, height } = this.state.dimensions
    const { children, lowResLayout, isLowResLayoutAt, onDimensionsUpdate } = this.props

    const isSmallDeviceScreen = isLowResLayoutAt && isLowResLayoutAt(width, height)

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.setState({ dimensions: contentRect.bounds })
          if (onDimensionsUpdate && contentRect.bounds) {
            onDimensionsUpdate(contentRect.bounds)
          }
        }}
      >
        {/*@ts-ignore*/}
        {({ measureRef }) => (
          <div style={{ width: '100%' }} ref={measureRef}>
            <LayoutGrey>{lowResLayout && isSmallDeviceScreen ? lowResLayout : children}</LayoutGrey>
          </div>
        )}
      </Measure>
    )
  }
}

export default LayoutGreyNoPortrait
