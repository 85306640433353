import { ISubmissionAttributes } from '../interfaces/submission'

const routerPath = '/api/submission'

export const getOpenSubmissions = async (token: string): Promise<ISubmissionAttributes[] | null> => {
  try {
    const response = await fetch(`${routerPath}/open`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })
    return response.ok ? response.json() : null
  } catch (error) {
    console.log(error)
    return null
  }
}
