import { FC, useEffect, useState } from 'react'

import Dropdown from '../../components/common/Dropdown'
import { ITypeAttributes } from '../../interfaces/type'
import TextInput from '../../components/common/Textinput'
import { AttributePlacement, IAttributeAttributes, IAttributeValidation } from '../../interfaces/attribute'

import ValidationEditor from '../../components/common/ValidationEditor'
import AttributeControl from '../../components/attributes/AttributeControl'
import { enumToIdTitleArray } from '../../utils/utils'
import { useTypeHierarchy } from '../../components/hooks/useTypeHierarchy'
import { SystemTypeEnum } from '../../enums/SystemTypeEnum'

import Style from './AddAttribute.module.sass'

interface IAddAttributesProps {
  attribute: IAttributeAttributes
  onValuesChanged?: (values: IAttributeValues, children: IAttributeAttributes[]) => void
}

export interface IAttributeValues {
  [id: string]: string
}

const AddAttribute: FC<IAddAttributesProps> = (props) => {
  const { attribute, onValuesChanged } = props
  const [types, setTypes] = useState<ITypeAttributes[]>()

  const [title, setTitle] = useState<string>(attribute.title)
  const [description, setDescription] = useState<string | undefined>(attribute.description)
  const [typeId, setTypeId] = useState<string>(attribute.typeId)
  const [sortOrder, setSortOrder] = useState<number>(attribute.sortOrder)
  const [placement, setPlacement] = useState<AttributePlacement>(attribute.placement)
  const [validation, setValidation] = useState<IAttributeValidation>(JSON.parse(attribute.validation || '{}'))
  const [test, setTest] = useState<string>('')
  const [children, setChildren] = useState<IAttributeAttributes[]>(attribute.children || [])
  const [reload, setReload] = useState<boolean>(false)

  const placementValues = enumToIdTitleArray(AttributePlacement).map((p) => ({ id: p.id.toString(), title: p.title }))

  const [values, setValues] = useState<IAttributeValues>({})
  const attributeType = useTypeHierarchy(SystemTypeEnum.Attributes)

  useEffect(() => {
    if (!attributeType?.children?.length) return
    setTypes(attributeType.children)
  }, [attributeType])

  useEffect(() => {
    onValuesChanged && onValuesChanged(values, children)
    setReload((val) => !val)
  }, [values, children]) // eslint-disable-line

  const onTypeChanged = (val: string) => {
    setValues((current) => {
      return { ...current, ['typeId']: val }
    })
    setTypeId(val)
  }

  const onTitleChanged = (val: string) => {
    setValues((current) => {
      return { ...current, ['title']: val }
    })
    setTitle(val)
  }
  const onDescriptionChanged = (val: string) => {
    setValues((current) => {
      return { ...current, ['description']: val }
    })
    setDescription(val)
  }

  const onPlacementChanged = (val: string) => {
    setValues((current) => {
      return { ...current, ['placement']: val }
    })
    setPlacement(Number(val))
  }

  const onSortOrderChanged = (val: string) => {
    setValues((current) => {
      return { ...current, ['sortOrder']: val }
    })
    setSortOrder(Number(val))
  }
  const onValidationChanged = (val: IAttributeValidation) => {
    setValues((current) => {
      return { ...current, ['validation']: JSON.stringify(val) }
    })
    setValidation(val)
  }
  const onAddChild = (childs: IAttributeAttributes[]) => {
    setChildren(childs)
    onValuesChanged && onValuesChanged(values, childs)
    setReload((val) => !val)
  }

  return (
    <div className={Style.wrapper}>
      <div className={Style.column}>
        <div className={Style.row}>
          <TextInput
            label='Title'
            validation={{ required: true, range: { max: 150 } }}
            value={title}
            onValueChanged={onTitleChanged}
          />
        </div>
        <div className={Style.row}>
          <Dropdown
            validation={{ required: true }}
            label='Type'
            value={typeId}
            options={attributeType?.children || []}
            onValueChanged={onTypeChanged}
          />
        </div>
        <div className={Style.row}>
          <TextInput
            label='Sort Order'
            validation={{ required: true, type: 'integer' }}
            value={sortOrder.toString()}
            onValueChanged={onSortOrderChanged}
          />
          <Dropdown
            validation={{ required: true }}
            label='Placement'
            value={placement.toString()}
            options={placementValues}
            onValueChanged={onPlacementChanged}
          />
        </div>
        <div className={Style.row}>
          <TextInput
            label='Description'
            validation={{ range: { max: 300 } }}
            value={description}
            onValueChanged={onDescriptionChanged}
          />
        </div>
        <div className={Style.row}>
          <AttributeControl
            onValueChanged={setTest}
            value={test}
            type={types?.find((t) => t.id == typeId)?.title || ''}
            attribute={{ ...attribute, children, title: 'test validation here' }}
            reload={reload}
          ></AttributeControl>
        </div>
      </div>
      <div className={Style.validation}>
        <ValidationEditor
          validation={validation}
          attribute={attribute}
          onChange={onValidationChanged}
          onChildreChanged={onAddChild}
        ></ValidationEditor>
      </div>
    </div>
  )
}

export default AddAttribute
