import { FC, ReactNode } from 'react'

import VoteButton from '../../../components/common/Button'

import styles from './Instructions.module.scss'

export interface IProps {
  children?: ReactNode
  // two display modes: "viewing instructions" and "instructions acknowledged"
  acknowledged: boolean
  // host component must handle the acknowledgement state / logic
  onAcknowledge: () => void
}

const RatingInstructions: FC<IProps> = (props) => {
  const { children, acknowledged, onAcknowledge } = props
  return (
    <section className={styles.wrap}>
      {children}
      <footer>{acknowledged ? null : <VoteButton onClick={onAcknowledge}>I accept. Let's begin!</VoteButton>}</footer>
    </section>
  )
}

export default RatingInstructions
