import { AppState, Auth0Provider } from '@auth0/auth0-react'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { config } from '../config/config'

export interface IProps {
  children?: React.ReactNode
}
export const Auth0ProviderWithHistory: FC<IProps> = (props) => {
  const { children } = props
  const navigate = useNavigate()

  const domain = config.AUTH0_DOMAIN
  const clientId = config.AUTH0_CLIENT_ID
  const redirectUri = window.location.origin
  const audience = config.AUTH0_AUDIENCE

  const onRedirectCallback = (appState?: AppState) => {
    const path = appState?.returnTo?.startsWith(redirectUri) ? appState.returnTo.slice(redirectUri.length) : redirectUri
    navigate(path, { replace: true })
  }

  if (!(domain && clientId && audience)) {
    return null
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  )
}
