// :TODO: finalise styles
import { FC } from 'react'
// import { Error as ErrorIcon } from '@mui/icons-material'

import Button from '../Button'

import styles from './ErrorDisplay.module.scss'

export interface IErrorProps {
  message: string
  error: Error
}
const Loading: FC<IErrorProps> = (props) => {
  const { message, error } = props
  return (
    <div className={styles.outer}>
      <h1>OHM NO!</h1>
      <p className={styles.message}>{message}</p>
      <pre className={styles.stack}>{error.toString()}</pre>
      <p>
        <Button onClick={() => (window.location.href = '/')}>Go home</Button>
      </p>
    </div>
  )
}

export default Loading
