import { IRequestAttributes } from '../interfaces/request'
import { IResponseAttributes } from '../interfaces/response'

const routerPath = `${process.env.REACT_APP_OHM_API_BASEURL || ''}/api/request`

export const getNextRequest = async (token: string, surveyId: string): Promise<IRequestAttributes | null> => {
  const response = await fetch(`${routerPath}/next/${surveyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  })
  return response.ok ? response.json() : null
}

export const saveResponse = async (
  token: string,
  surveyId: string,
  responses: IResponseAttributes[],
): Promise<IRequestAttributes | null> => {
  const response = await fetch(`${routerPath}/save/${surveyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(responses),
  })
  return response.ok ? response.json() : null
}
