import { FormControl, FormHelperText, Icon, InputLabel, OutlinedInput, Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import { IAttributeValidation } from '../../interfaces/attribute'

import Style from '../../styles/controls/Controls.module.sass'
import { useValidation } from '../hooks/useValidation'

interface ITextInputProps {
  value?: string
  label?: string
  tooltip?: string
  validation?: IAttributeValidation
  readonly?: boolean
  onValueChanged?: (val: string) => void
  onErrorChanged?: (error: string) => void
}
const TextInput: FC<ITextInputProps> = (props) => {
  const { value, label, validation = {}, tooltip, readonly, onErrorChanged, onValueChanged } = props
  const [focused, setFocused] = useState(true)

  const [error, helperText] = useValidation(validation, value, focused, onErrorChanged)

  return (
    <div className={Style.wrapper}>
      <FormControl fullWidth>
        <InputLabel required={validation.required}>{label}</InputLabel>
        <div className={Style.inputIcon}>
          <OutlinedInput
            fullWidth
            label={`${label}${validation.required ? ' *' : ''}`}
            autoComplete='off'
            //inputProps={{ 'data-required': true }}
            //required={validation?.required}
            error={error}
            value={value || ''}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={(e) => onValueChanged && onValueChanged(e.target.value)}
            readOnly={readonly}
          />
          {tooltip && (
            <Tooltip title={tooltip}>
              <Icon>help_centre</Icon>
            </Tooltip>
          )}
        </div>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  )
}

export default TextInput
