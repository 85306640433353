import { useAuth0 } from '@auth0/auth0-react'
import { Menu as MenuIcon } from '@mui/icons-material'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { APP_NAME } from '../../config/config'
import { ContributorRoleEnum } from '../../enums/ContributorRoleEnum'
import { ContributorRole } from '../../stores/appStore'

//import Style from '../../styles/controls/Header.module.sass'

interface IHeaderProps {
  header?: string
  onClick?: () => void
}
const drawerWidth = 240

const Header: FC<IHeaderProps> = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const [mobileOpen, setMobileOpen] = useState(false)

  const contributorRole = useRecoilValue(ContributorRole)

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const nav = useNavigate()

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const loginClicked = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
    })
  }

  const logoutClicked = async () => {
    logout({
      returnTo: window.location.origin,
    })
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const menuItems = [{ label: 'Ratings', link: '/ratings', icon: 'edit_note' }]
  if (contributorRole === ContributorRoleEnum.Manager) {
    menuItems.push(
      //{ label: 'Organisation', link: '/organisation', icon: 'edit_note' },
      { label: 'Templates', link: '/templates', icon: 'edit_note' },
      { label: 'Submissions', link: '/submissions', icon: 'edit_note' },
      { label: 'Profile', link: '/profile', icon: 'edit_note' },
    )
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        {APP_NAME}
      </Typography>
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.link} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title='Open settings'>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt='Remy Sharp' />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id='menu-appbar'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem key={'profile'} onClick={() => nav('/profile')}>
            <Typography textAlign='center'>{'profile'}</Typography>
          </MenuItem>
          {isAuthenticated ? (
            <MenuItem key={'logout'} onClick={() => logoutClicked()}>
              <Typography textAlign='center'>{'logout'}</Typography>
            </MenuItem>
          ) : (
            <MenuItem key={'login'} onClick={() => loginClicked()}>
              <Typography textAlign='center'>{'login'}</Typography>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>
  )
  return (
    <>
      <AppBar component={'nav'} position='static'>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            {APP_NAME}
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {isAuthenticated &&
              menuItems.map((item) => (
                <Button key={item.link} sx={{ color: '#fff' }} onClick={() => nav(item.link)}>
                  {item.label}
                </Button>
              ))}
            {isAuthenticated ? (
              <Button key={'logout'} sx={{ color: '#fff', minWidth: '32px' }} onClick={() => logoutClicked()}>
                <Icon>logout</Icon>
              </Button>
            ) : (
              <Button key={'login'} onClick={() => loginClicked()}>
                <Typography textAlign='center'>{'login'}</Typography>
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          container={window.document.body}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  )
}
export default Header
