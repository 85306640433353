import { FC } from 'react'
import { IItemAttributes } from '../../../interfaces/item'

import Style from './ItemRow.module.scss'
import TextInput from '../../../components/common/Textinput'
import { useItem } from '../../../components/hooks/useItem'
import { useTypeHierarchy } from '../../../components/hooks/useTypeHierarchy'

interface IItemRowProps {
  item: IItemAttributes
}

const ItemRow: FC<IItemRowProps> = (props) => {
  const { item } = props
  const parent = useItem(item.parentId)
  const itemType = useTypeHierarchy(item.typeId)
  const type = item.type || itemType

  return (
    <div className={Style.row}>
      <TextInput label={`${parent?.title} - ${type?.title}`} value={item.title} readonly></TextInput>
    </div>
  )
}

export default ItemRow
