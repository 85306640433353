import { FC, MouseEvent } from 'react'
import { Icon } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styles from './RatingHeader.module.scss'
import { ContributorRoleEnum } from '../../../enums/ContributorRoleEnum'
import { useRecoilValue } from 'recoil'
import { ContributorRole } from '../../../stores/appStore'
import { useAuth0 } from '@auth0/auth0-react'

export interface IProps {
  question: string
  totalQuestions: number
  currentQuestionIdx: number
  isSaving?: boolean
  ohmnomsBalance?: number
}
const RatingHeader: FC<IProps> = (props) => {
  const { logout } = useAuth0()
  const { question, totalQuestions, currentQuestionIdx, isSaving, ohmnomsBalance } = props
  const questionProgressPercent = isSaving ? 100 : (currentQuestionIdx / totalQuestions) * 100

  const contributorRole = useRecoilValue(ContributorRole)

  const nav = useNavigate()

  const exitClicked = (e: MouseEvent<HTMLAnchorElement>) => {
    if (contributorRole === ContributorRoleEnum.Manager) {
      nav('/profile')
    } else {
      logout({ returnTo: window.location.origin })
    }
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <section className={styles.outer}>
      <menu>
        <a href={window.location.origin} title='Exit' onClick={exitClicked}>
          <Icon>logout</Icon>
        </a>
      </menu>
      <h1>{question}</h1>
      <aside className={styles.noms + (isSaving ? ` ${styles.saving}` : '')}>
        <p>{ohmnomsBalance || '0'}</p>
        <span className={styles.ohm}>ohm</span>
        <span className={styles.nom}>nom</span>
        <img className={styles.bg} src='/OHM-counter-bg.svg' />
      </aside>
      <div className={styles.progress} style={{ width: `${questionProgressPercent}%` }} />
    </section>
  )
}

export default RatingHeader
