import { FC } from 'react'

import styles from './Login.module.scss'

import { useAuth0 } from '@auth0/auth0-react'
import Layout from '../components/layouts/LayoutGrey'
import Button from '../components/common/Button'
import OHMLogo from '../components/common/OHMLogo'
import Loading from '../components/common/LoadingMessage/LoadingMessage'

const LoginPage: FC = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

  const loginClicked = async () => {
    await loginWithRedirect({
      prompt: 'login',
      appState: {
        returnTo: window.location.origin,
      },
    })
  }

  return (
    <Layout>
      <div className={styles.main}>
        <OHMLogo />
        <p>
          {isLoading && <Loading message='Logging you in...'></Loading>}
          {!isAuthenticated && !isLoading && <Button onClick={() => loginClicked()}>Login</Button>}
        </p>
      </div>
    </Layout>
  )
}

export default LoginPage
