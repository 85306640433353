import createAuth0Client from '@auth0/auth0-spa-js'
import { isExpired } from 'react-jwt'
import { config } from '../config/config'

// eslint-disable-next-line
let tokenMgr: any = null

// eslint-disable-next-line
export function TokenManager() {
  if (tokenMgr) {
    return tokenMgr
  }

  const EMPTY_STRING_ARRAY: string[] = []
  const NO_TOKEN = ''

  const token = {
    jwt: NO_TOKEN,
    scopes: EMPTY_STRING_ARRAY,
    expired: false,
  }

  tokenMgr = new Promise((resolve) => {
    createAuth0Client({
      domain: config.AUTH0_DOMAIN,
      client_id: config.AUTH0_CLIENT_ID,
    }).then((auth0) => {
      async function tokenRequest(scopes: string[]) {
        const scope = ['read:current_user', ...scopes].join(' ')
        const opts = {
          audience: config.AUTH0_AUDIENCE,
          scope: scope,
        }

        try {
          return await auth0.getTokenSilently(opts)
          // eslint-disable-next-line
        } catch (error: any) {
          if (error.error === 'login_required') {
            console.error(`Login required`, error)
            return await auth0.getTokenWithPopup(opts)
          } else {
            throw new Error(`Unknown error: ${error.error}`)
          }
        }
      }

      function requestToken(scopes: string[]): Promise<string> {
        return new Promise((resolve2) => {
          const jwtToken = token.jwt
          if (!jwtToken || token.expired) {
            tokenRequest(scopes).then((newToken) => {
              token.jwt = newToken
              token.scopes = scopes
              resolve2(token.jwt)
            })
          } else {
            resolve2(token.jwt)
          }
        })
      }

      function handleTokenExpiry() {
        token.expired = true
      }

      function checkTokenExpiration(tokenString: string) {
        return isExpired(tokenString)
      }

      tokenMgr = {
        requestToken,
        handleTokenExpiry,
        checkTokenExpiration,
      }

      resolve(tokenMgr)
    })
  })
  return tokenMgr
}
