// Button for rating page vote buttons.
// Prevents accidental preference selection from rapid clicks.
import { FC, ReactNode, MouseEventHandler } from 'react'

import Button from '../../../components/common/Button'

// @ts-ignore
import debounce from 'debounce'

export interface IProps {
  onClick: MouseEventHandler<HTMLAnchorElement>
  children?: ReactNode
  disabled?: boolean
}
const VoteButon: FC<IProps> = (props) => {
  const newProps = {
    ...props,
    onClick: debounce(props.onClick, 200),
  }
  return <Button {...newProps} />
}

export default VoteButon
